import React, { useEffect } from "react";
import { Row, Col, Card, CardBody, CardTitle, Container } from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import { resellerSelector, getPatientsResults } from "../../slices/ResellerSlice";
// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import overlayFactory from 'react-bootstrap-table2-overlay';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import filterFactory, { selectFilter } from 'react-bootstrap-table2-filter';
import { API_URL } from "../../app/config";

//Import Breadcrumb
//import Breadcrumbs from "../../components/Common/Breadcrumb"
import "../../assets/scss/datatables.scss"
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';

const Results = () => {
  const dispatch = useDispatch();
  const { loading, results } = useSelector(resellerSelector);

  useEffect(() => {
    dispatch(getPatientsResults());
  }, [dispatch]);


  async function downloadPdf(imageSrc, name) {
    const image = await fetch(imageSrc, { 
      headers: new Headers({
        'Authorization': `Bearer ${localStorage.getItem("accessToken")}`,
      })
    });

    const imageBlog = await image.blob()
    const imageURL = URL.createObjectURL(imageBlog)
  
    const link = document.createElement('a')
    link.href = imageURL
    link.download = name+'.pdf'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }


  function nameFormatter(cell, row) {
    return `${row.patient.firstname} ${row.patient.lastname}`
  } 


  /* TIPOVI TESTOVA DROPDOWN */
  const selectOptions = Object.assign(
    {}, 
    ...results.map(({name, achievements}) => ({ [name]: name }))
  );


  const nameFilter = selectFilter({
    options: selectOptions,
    placeholder: 'Izaberite naziv testa',  // custom the input placeholder
    className: '',
  });

  const columns = [{
    dataField: 'name',
    text: 'Ime i prezime',
    sort: true,
    formatter: nameFormatter
  }, {
    dataField: 'name',
    text: 'Naziv pretrage',
    sort: true,
    filter: nameFilter
  }, {
    dataField: 'rezultat',
    text: 'Rezultat',
    sort: true
  }, {
    dataField: 'created_at',
    text: 'Datum',
    sort: true,
  }, {
    dataField: "menu",
    isDummyField: true,
    editable: false,
    text: 'Akcije',
    sort: true,
    formatter: (cellContent, result) => (
      <div className="d-flex gap-3">
        <div 
          className="text-success"
          onClick={() => {
            downloadPdf(API_URL+"/reseller/results/"+result.pid+"/pdf", result.patient.firstname + '_' + '_'+result.patient.lastname + '_' + result.pid)
          }}
        >
          <i className="mdi mdi-file-pdf font-size-18"></i>
        </div>
      </div>
    ),
  }];

  const defaultSorted = [{
    dataField: 'created_at',
    order: 'desc'
  }];

  const pageOptions = {
    sizePerPage: 10,
    totalSize: results.length, // replace later with size(customers),
    custom: true,
  }

  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: '5', value: 5 },
    { text: '10', value: 10 },
    { text: '15', value: 15 },
    { text: '20', value: 20 },
    { text: '25', value: 25 },
    { text: 'All', value: (results).length }];

    const { SearchBar } = Search;

    const rowStyle = (row, rowIndex) => {
      let style = {};
      if(row.rezultat == "negativan"){
        style = { backgroundColor: "#c8fac6" };
      }else if(parseFloat(row.rezultat) >= parseFloat(row.refg)){
        style = { backgroundColor: "#f7cccc" };
      }else if(parseFloat(row.rezultat) <= parseFloat(row.refg)){
        style = { backgroundColor: "#c8fac6" };
      }else if((parseFloat(row.rezultat) <= parseFloat(row.refg)) && parseFloat(row.rezultat) >= parseFloat(row.refd)){
        style = { backgroundColor: "#c8fac6" };
      }
      return style;
    };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/*<Breadcrumbs title="Tables" breadcrumbItem="Data Tables" />*/}
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <CardTitle className="h4">Rezultati</CardTitle>
                    <p className="card-title-desc">Prikaz rezultata svih analiza urađenih kod nas.</p>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField='test'
                      columns={columns}
                      data={results}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField='test'
                          columns={columns}
                          data={results}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>

                              <Row className="mb-2">
                                <Col md="4">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        placeholder="Pretraga"
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={"test"}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      classes={
                                        "table align-middle table-nowrap"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                      rowStyle={ rowStyle }
                                      /*overlay={ 
                                        overlayFactory({
                                          spinner: true,
                                          styles: { 
                                            spinner: (base) => ({
                                              ...base, 
                                              '& svg circle': {
                                                stroke: '#80c44a'
                                              }
                                            })
                                          } 
                                        }) 
                                      }*/
                                      loading={loading}
                                      noDataIndication={ () => (<div>{loading ? "Ucitavanje...": "Nema rezultata."}</div>) }
                                      filter={filterFactory()}
                                    />

                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )
                          }
                        </ToolkitProvider>
                      )
                      }</PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export { Results as ResellerResults }
