import React from "react"
import { Link } from "react-router-dom"
import { Col, Container, Form, FormGroup, Label, Row, Input } from "reactstrap"

import { AvForm, AvField } from "availity-reactstrap-validation"
import { useDispatch, useSelector } from "react-redux";
import { forgotPassword, userSelector, clearState } from "../../slices/UserSlice";
// import images
import CarouselPage from "./CarouselPage"

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const { loading, error, errors } = useSelector(userSelector);

  const handleForgotPassword = (event, errors, values) => {
    event.preventDefault();
    console.log("KLIK");
    //poziv
    dispatch(forgotPassword(values));
  };

  return (
    <React.Fragment>
       <div>
          <Container fluid className="p-0">
            <Row className="g-0">
              <CarouselPage />
              <Col xl={3}>
                <div className="auth-full-page-content p-md-5 p-4">
                  <div className="w-100">
                    <div className="d-flex flex-column h-100">
                      <div className="my-auto">
                        <div>
                          <h5 className="text-primary">Zahtjev za resetovanje šifre</h5>
                        </div>

                        <div className="mt-4">
                          <AvForm className="form-horizontal" onSubmit={handleForgotPassword}>
                            <div className="mb-3">
                              <AvField
                                name="username"
                                label="Korisničko ime"
                                className="form-control"
                                placeholder="Unesite korisničko ime"
                                type="text"
                                required
                                errorMessage="Molimo unesite korisničko ime"
                              />
                            </div>
                            <div className="mt-3 d-grid">
                              <button
                                className="btn btn-primary btn-block "
                                type="submit"
                              >
                                {loading ? "Učitavanje...": "Resetuj"}
                              </button>
                            </div>
                            <div className="mt-3 d-grid">
                              <Link
                                to="/auth/login"
                                className="btn btn-primary btn-block"
                              >
                                Vrati se na prijavu
                              </Link>
                            </div>
                          </AvForm>
                        </div>
                      </div>

                      <div className="mt-4 mt-md-5 text-center">
                        <p className="mb-0">
                          © {new Date().getFullYear()} Labos.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
    </React.Fragment>
  )
}

export default ForgotPassword
