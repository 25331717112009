import React from 'react';
import { Switch, Route, Link, Redirect } from 'react-router-dom';
import { UserRoute } from './UserRoute';
import { AuthRoute } from './AuthRoute';
import { AdminRoute } from './AdminRoute';
import { ResellerRoute } from './ResellerRoute';
import Login from '../pages/Auth/Login';
import CalendarRegister from '../pages/Auth/CalendarRegister';
import Register from '../pages/Auth/Register';
import ForgotPassword from '../pages/Auth/ForgotPassword';
import ResetPassword from '../pages/Auth/ResetPassword';
import Home from '../pages/User/Home';
import Results from '../pages/User/Results'
import CreatePatient from '../pages/Reseller/CreatePatient';
import Patients from '../pages/Reseller/Patients';
import {ResellerResults} from '../pages/Reseller/Results';
import {AdminPatients} from '../pages/Admin/Patients';
import {AdminResellers} from '../pages/Admin/Resellers';
import Activities from '../pages/Admin/Activities';
import UpcomingBirthdays from '../pages/Admin/UpcomingBirthdays';
import Samplings from '../pages/Admin/Samplings';;
import Profile from '../pages/User/Profile';
import Pages404 from '../components/Pages404'
import AppointmentCalendar from '../pages/Admin/AppointmentCalendar';
import AppointmentVerify from '../pages/Auth/AppointmentVerify';

/*
      <AuthRoute exact component={CalendarRegister} path="/auth/appointment" />
      <AuthRoute exact component={AppointmentVerify} path="/auth/appointment/verify" />
*/
function AppRouter() {
  return (
    <Switch>
            {/*
      <AuthRoute exact component={CalendarRegister} path="/" />
      <AuthRoute exact component={AppointmentVerify} path="/auth/appointment/verify" />*/}
      <Redirect exact from="/" to="/auth/login" />
      <AuthRoute exact component={Login} path="/auth/login" />
      <AuthRoute exact component={CalendarRegister} path="/auth/appointment" />
      <AuthRoute exact component={AppointmentVerify} path="/auth/appointment/verify" />
      <Route exact component={Register} path="/auth/register" />
      <Route exact component={Register} path="/auth/register/:resellerUsername" />
  
      <AuthRoute exact component={ForgotPassword} path="/auth/forgotpassword" />
      <AuthRoute exact component={ResetPassword} path="/auth/resetpassword" />
      <AuthRoute exact component={CalendarRegister} path="/auth/appointment" />

      <UserRoute exact component={Home} path="/user/home" />
      <UserRoute exact component={Results} path="/me/results" />
      <UserRoute exact component={Profile} path="/me/profile" />

      <ResellerRoute exact component={CreatePatient} path="/reseller/patients/add" />
      <ResellerRoute exact component={Patients} path="/reseller/patients" />
      <ResellerRoute exact component={ResellerResults} path="/reseller/results" />

      <AdminRoute exact component={AdminPatients} path="/admin/patients" />
      <AdminRoute exact component={AdminResellers} path="/admin/resellers" />
      <AdminRoute exact component={Samplings} path="/admin/samplings" />
      <AdminRoute exact component={Activities} path="/admin/activities" />
      <AdminRoute exact component={UpcomingBirthdays} path="/admin/upcoming-birthdays" />
      <AdminRoute exact component={AppointmentCalendar} path="/admin/appointments" />
      <Route path="/404" component={Pages404} />
      <Redirect to="/404" />
    </Switch>
  );
}

export default AppRouter;
