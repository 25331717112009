
import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { userSelector } from "../slices/UserSlice";

export const AuthRoute = ({
  component,
  path,
  ...rest
}) => {

const { accessToken } = useSelector(userSelector);
  return accessToken === null ? (
    <Route exact path={path} component={component} {...rest} />
  ) : (
      <Redirect to={"/user/home"} />
    );
};