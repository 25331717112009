import React, { useEffect,Component } from "react";
import { Row, Col, Card, CardBody, CardTitle, Container } from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import { userSelector, getResults } from "../../slices/UserSlice";
// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import overlayFactory from 'react-bootstrap-table2-overlay';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import filterFactory, { selectFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';

//Import Breadcrumb
//import Breadcrumbs from "../../components/Common/Breadcrumb"
import "../../assets/scss/datatables.scss"

const Results = () => {
  const dispatch = useDispatch();
  const { loading, results } = useSelector(userSelector);

  useEffect(() => {
    dispatch(getResults());
  }, [dispatch]);

  function testFormatter(cell, row) {
    return `${row.refd} - ${row.refg}`
  } 


  function getCustomFilter(filterHandler, customFilterParameters) {
    return (
      <RangeFilter filterHandler={filterHandler} />
    );
  }
  /* TEST DATE RANGE */

  /* TIPOVI TESTOVA DROPDOWN */
  const selectOptions = Object.assign(
    {}, 
    ...results.map(({name, achievements}) => ({ [name]: name }))
  );


  const nameFilter = selectFilter({
    options: selectOptions,
    placeholder: 'Izaberite naziv testa',  // custom the input placeholder
    className: '',
  });

  const columns = [{
    dataField: 'name',
    text: 'Naziv pretrage',
    sort: true,
    filter: nameFilter
  }, {
    dataField: 'rezultat',
    text: 'Rezultat',
    sort: true
  }, {
    dataField: 'refd',
    text: 'Referentni interval',
    sort: true,
    formatter: testFormatter
  }, {
    dataField: 'jedinica',
    text: 'Mjerna jedinica',
    sort: true
  }, {
    dataField: 'odobren_at',
    text: 'Datum',
    sort: true,
  }, {
    dataField: 'salary',
    text: 'Akcije',
    sort: true
  }];

  const defaultSorted = [{
    dataField: 'id',
    order: 'asc'
  }];

  const pageOptions = {
    sizePerPage: 10,
    totalSize: results.length, // replace later with size(customers),
    custom: true,
  }

  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: '5', value: 5 },
    { text: '10', value: 10 },
    { text: '15', value: 15 },
    { text: '20', value: 20 },
    { text: '25', value: 25 },
    { text: 'All', value: (results).length }];

    const expandRow = {
      onlyOneExpanding: true,
      showExpandColumn: true,
      nonExpandable: results.filter(x => x.expandable == false).map(x => x.test),
      renderer: row => (
        <BootstrapTable
          keyField={"test"}
          responsive
          bordered={false}
          striped={false}
          columns={[{
              dataField: 'name',
              text: 'Naziv pretrage',
              sort: true,
            }, {
              dataField: 'rezultat',
              text: 'Rezultat',
              sort: true
            }, {
              dataField: 'refd',
              text: 'Referentni interval',
              sort: true,
              formatter: testFormatter
            }, {
              dataField: 'jedinica',
              text: 'Mjerna jedinica',
              sort: true
            }, {
              dataField: 'odobren_at',
              text: 'Datum',
              sort: true,
            }, {
              dataField: 'salary',
              text: 'Akcije',
              sort: true
            }]}
          data={row.sub}
          defaultSorted={defaultSorted}
          classes={
            "table align-middle table-nowrap"
          }
          rowStyle={ rowStyle }
        />
      )
    };


    const { SearchBar } = Search;

    const rowStyle = (row, rowIndex) => {
      let style = {};
      if(row.rezultat == "negativan"){
        style = { backgroundColor: "#c8fac6" };
      }else if(parseFloat(row.rezultat) >= parseFloat(row.refg)){
        style = { backgroundColor: "#f7cccc" };
      }else if(parseFloat(row.rezultat) <= parseFloat(row.refg)){
        style = { backgroundColor: "#c8fac6" };
      }else if((parseFloat(row.rezultat) <= parseFloat(row.refg)) && parseFloat(row.rezultat) >= parseFloat(row.refd)){
        style = { backgroundColor: "#c8fac6" };
      }
      return style;
    };

    
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/*<Breadcrumbs title="Tables" breadcrumbItem="Data Tables" />*/}
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <CardTitle className="h4">Rezultati</CardTitle>
                    <p className="card-title-desc">Prikaz rezultata svih analiza urađenih kod nas.</p>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField='test'
                      columns={columns}
                      data={results}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                        bootstrap4
                          keyField='test'
                          columns={columns}
                          data={results}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>

                              <Row className="mb-2">
                                <Col md="4">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        placeholder="Pretraga"
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={"test"}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      condensed={true}
                                      expandRow={ expandRow }
                                      defaultSorted={defaultSorted}
                                      classes={
                                        "table align-middle table-nowrap"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                      rowStyle={ rowStyle }
                                      
                                      loading={loading}
                                      noDataIndication={ () => (<div>{loading ? "Ucitavanje...": "Nema rezultata."}</div>) }
                                      filter={filterFactory()}
                                    />

                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )
                          }
                        </ToolkitProvider>
                      )
                      }</PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Results
