import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, FormGroup, Form, FormFeedback, CardTitle, Input, Label, Container, Modal, ModalHeader, ModalBody } from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import { adminSelector, getResellerStatistics, getResellers, getResellerByid, createReseller, updateReseller, clearErrors } from "../../slices/AdminSlice";
import { AvForm, AvField } from "availity-reactstrap-validation"
import { Link } from "react-router-dom"
import ReactApexChart from "react-apexcharts"

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import overlayFactory from 'react-bootstrap-table2-overlay';
import * as c from "../../app/config";

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import filterFactory, { multiSelectFilter } from 'react-bootstrap-table2-filter';
import DoubleScrollbar from 'react-double-scrollbar'
import StackedChart from "../../components/Charts/StackedChart"

//Import Breadcrumb
//import Breadcrumbs from "../../components/Common/Breadcrumb"
import "../../assets/scss/datatables.scss"

const Resellers = () => {
  const dispatch = useDispatch();
  const { filter, errors, statisticLoading, statistics, loading, resellers } = useSelector(adminSelector);
  const [statisticModal, setStatisticModal] = useState(false);
  const [hotelChecked, setHotelChecked] = useState(false)
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [user, setUser] = useState([])
  const toggle = () => {
    setModal(!modal)
  }

  const toggleStatisticModal = () => {
    setStatisticModal(!statisticModal)
  }

  const [ avatar, setAvatar ] = useState('');
  /* RESELLER EDIT */
  const handleEditClick = (editUser) => {
    dispatch(clearErrors());
    setUser(editUser);
    setIsEdit(true);
    toggle();
  }

  /* ADD NEW */
  const handleAddNew = () => {
    dispatch(clearErrors());
    setUser('');
    setIsEdit(false);
    toggle();
  }

  /* HANDLE SUBMIT I ZA EDIT I ZA NEW */
  const handleValidSubmit = (event, errors, values) => {
    event.preventDefault();
    if(isEdit){
      // update
      values['id'] = user.id;
      values['avatar'] = avatar;
      values['isHotel'] = hotelChecked;
      dispatch(updateReseller(values, toggle));
    }else{
      values['avatar'] = avatar;
      values['isHotel'] = hotelChecked;
      dispatch(createReseller(values, toggle));
    }
    //toggle()
  }

  /* STATISTIKA */
  const handleStatisticButton = (user) => {
    setUser(user);
    toggleStatisticModal();
    dispatch(getResellerStatistics(user.id));
  }
  let pcr2 = !statisticLoading ? statistics?.monthly?.pcr : 0
  let antigen2 = !statisticLoading ? statistics?.monthly?.antigen : 0
  const stackedChartSeries = [
    {
      name: "PCR",
      data: pcr2,
    },
    {
      name: "Antigen",
      data: antigen2
    },
  ];

  // getanje svih resellera
  useEffect(() => {
    dispatch(getResellers());
  }, [dispatch]);
  
  const columns = [{
    dataField: 'id',
    text: 'ID',
    sort: true,
    hidden: true
  },{
    dataField: "avatar",
    text: "Avatar",
    formatter: (cellContent, user) => (
      <>
        {!user.avatar ? (
          <div className="avatar-xs">
            <span className="avatar-title rounded-circle">
              {user.name.charAt(0)}
            </span>
          </div>
        ) : (
          <div>
            <img
              className="rounded-circle avatar-xs"
              src={`${c.API_URL}/${user.avatar}`}
              alt=""
            />
          </div>
        )}
      </>
    ),
  }, {
    dataField: 'totalPatients',
    text: 'Broj pacijenata',
    sort: true,
    formatter: (cellContent, user) => (
      <div
        className="badge badge-soft-secondary font-size-11 m-1"
      >
        {user.totalPatients}
      </div>
    ),
  },{
    dataField: 'username',
    text: 'Korisničko ime',
    sort: true,
  }, {
    dataField: 'name',
    text: 'Naziv',
    sort: true,
  }, {
    dataField: 'email',
    text: 'Email',
    sort: true
  },{
    dataField: 'phone',
    text: 'Broj telefona',
    sort: true,
  },{
    dataField: 'ass',
    text: 'Dodatne informacije',
    isDummyField: true,
    sort: true,
    formatter: (cellContent, user) => (
      <div>
        {user.additional_info != null &&
          <div>
            <div className="badge badge-soft-primary font-size-11 m-1">Adresa: {user.additional_info.address}</div>
            <div className="badge badge-soft-primary font-size-11 m-1">Identifikacioni broj: {user.additional_info.id_number}</div>
            <div className="badge badge-soft-primary font-size-11 m-1">PDV broj: {user.additional_info.pdv_number}</div>
          </div>
        }
      </div>
    ),
  },{
    dataField: 'roles',
    text: 'Uloge',
    sort: true,
    formatter: (cellContent, user) => (
      <>
        {
          user.roles.map((role, i) =>
            <div
              className="badge badge-soft-primary font-size-11 m-1"
              key={"_role_" + user.id + i}
            >
              {role.name}
            </div>  
          )
        }
      </>
    ),
  }, {
    dataField: 'created_at',
    text: 'Datum registracije',
    sort: true,
  }, {
    dataField: 'updated_at',
    text: 'Datum zadnje aktivnosti',
    sort: true,
  }, {
    dataField: "menu",
    isDummyField: true,
    editable: false,
    text: 'Akcije',
    sort: true,
    formatter: (cellContent, result) => (
      <div className="d-flex gap-3">
        <div 
          className="text-success"
          onClick={() => handleEditClick(result)}
        >
          <i className="mdi mdi-pencil font-size-18"></i>
        </div>
        
        <Link to={`/admin/patients?filter=${result.id}`}>
          <div className="text-danger">
            <i className="fas fa-users font-size-18"></i>
          </div>
        </Link>
        <div className="text-end">
          <button
            type="submit"
            className="btn btn-success save-user"
            onClick={() => handleStatisticButton(result)}
          >Statistika</button>
        </div>
      </div>
    ),
  }];

  const defaultSorted = [{
    dataField: 'id',
    order: 'asc'
  }];

  const pageOptions = {
    sizePerPage: 10,
    totalSize: resellers.length, // replace later with size(customers),
    custom: true,
  }

  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: '5', value: 5 },
    { text: '10', value: 10 },
    { text: '15', value: 15 },
    { text: '20', value: 20 },
    { text: '25', value: 25 },
    { text: 'All', value: (resellers).length }
  ];

  const { SearchBar } = Search;

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <CardTitle className="h4">Pregled resellera</CardTitle>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField='name'
                      columns={columns}
                      data={resellers}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField='name'
                          columns={columns}
                          data={resellers}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col md="10">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        placeholder="Pretraga"
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                                <Col md="2">
                                  <div className="button-items">
                                    <button 
                                      onClick={handleAddNew}
                                      className="btn btn-success">Dodaj resellera</button>
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <DoubleScrollbar>
                                      <BootstrapTable
                                        keyField={"test"}
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        defaultSorted={defaultSorted}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        headerWrapperClasses={"thead-light"}
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                        overlay={ 
                                          overlayFactory({
                                            spinner: true,
                                            styles: { 
                                              spinner: (base) => ({
                                                ...base, 
                                                '& svg circle': {
                                                  stroke: '#80c44a'
                                                }
                                              })
                                            } 
                                          }) 
                                        }
                                        loading={loading}
                                        noDataIndication={ () => (<div>{loading ? "Ucitavanje...": "Nema rezultata."}</div>) }
                                      />
                                    </DoubleScrollbar>
                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )
                          }
                        </ToolkitProvider>
                      )
                      }</PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {/* MODAL ZA STATISTKU RESELLERA */}
            <Modal
              isOpen={statisticModal}
              toggle={toggleStatisticModal}
            >
              <ModalHeader toggle={toggleStatisticModal} tag="h4">
                Statistika za {user?.name}
              </ModalHeader>
              <ModalBody>
                <Row>
                  <Col xl="12">
                    <Card className="mini-stats-wid">
                      {statisticLoading &&
                        <div className="position-absolute overlay bg-light w-100 h-100 d-flex justify-content-center align-items-center" style={{width: 300, height: 200, zIndex: 3}}>
                          <div className="">  
                            <div className="spinner-grow text-primary" role="status" style={{width: 30, height: 30, zIndex: 20}}>
                              <span className="sr-only">Loading...</span>
                            </div>
                          </div>
                        </div>
                      }
                      <CardBody>
                        {!statisticLoading && <StackedChart
                          series={stackedChartSeries}
                        />
                        }
                        <Col xl={12}>
                          <Row>
                            <Col lg={3}>
                              <Card className="mini-stats-wid">
                                <CardBody>
                                  <div className="d-flex flex-wrap">
                                    <div className="me-3">
                                      <p className="text-muted mb-2">Broj pacijenata</p>
                                      <h5 className="mb-0"> {statisticLoading ? <div class="spinner-grow text-success" role="status"><span class="sr-only">Loading...</span></div>: statistics?.total}</h5>
                                    </div>
                                  </div>
                                </CardBody>
                              </Card>
                            </Col>
                            <Col lg={3}>
                              <Card className="blog-stats-wid">
                                <CardBody>
                                  <div className="d-flex flex-wrap">
                                    <div className="me-3">
                                      <p className="text-muted mb-2">Danas</p>
                                      <h5 className="mb-0"> {statisticLoading ? <div class="spinner-grow text-success" role="status"><span class="sr-only">Loading...</span></div>: statistics?.patientsToday?.total?.count}</h5>
                                      <p class="card-text float-right">
                                        <small class="text-muted">{statistics?.patientsToday?.total?.money} KM</small>
                                      </p>
                                    </div>
                                  </div>
                                </CardBody>
                              </Card>
                            </Col>
                            <Col lg={3}>
                              <Card className="blog-stats-wid">
                                <CardBody>
                                  <div className="d-flex flex-wrap">
                                    <div className="me-3">
                                      <p className="text-muted mb-2">Ovaj mjesec</p>
                                      <h5 className="mb-0"> {statisticLoading ? <div class="spinner-grow text-success" role="status"><span class="sr-only">Loading...</span></div>: statistics?.patientsThisMonth?.total?.count}</h5>
                                      <p class="card-text float-right">
                                        <small class="text-muted">{statistics?.patientsThisMonth?.total?.money} KM</small>
                                      </p>
                                    </div>
                                  </div>
                                </CardBody>
                              </Card>
                            </Col>
                            <Col lg={3}>
                              <Card className="blog-stats-wid">
                                <CardBody>
                                  <div className="d-flex flex-wrap">
                                    <div className="me-3">
                                      <p className="text-muted mb-2">Ove godine</p>
                                      <h5 className="mb-0"> {statisticLoading ? <div class="spinner-grow text-success" role="status"><span class="sr-only">Loading...</span></div>: statistics?.patientsThisYear?.total?.count}</h5>
                                      <p class="card-text float-right">
                                        <small class="text-muted">{statistics?.patientsThisYear?.total?.money} KM</small>
                                      </p>
                                    </div>
                                  </div>
                                </CardBody>
                              </Card>
                            </Col>
                            <Col lg={3}>
                              <Card className="blog-stats-wid">
                                <CardBody>
                                  <div className="d-flex flex-wrap">
                                    <div className="me-3">
                                      <p className="text-muted mb-2">PCR / Danas</p>
                                      <h5 className="mb-0"> {statisticLoading ? <div class="spinner-grow text-success" role="status"><span class="sr-only">Loading...</span></div>: statistics?.patientsToday?.pcr?.count}</h5>
                                      <p class="card-text float-right">
                                        <small class="text-muted">{statistics?.patientsToday?.pcr?.money} KM</small>
                                      </p>
                                    </div>
                                  </div>
                                </CardBody>
                              </Card>
                            </Col>
                            <Col lg={3}>
                              <Card className="blog-stats-wid">
                                <CardBody>
                                  <div className="d-flex flex-wrap">
                                    <div className="me-3">
                                      <p className="text-muted mb-2">Antigen / Danas</p>
                                      <h5 className="mb-0"> {statisticLoading ? <div class="spinner-grow text-success" role="status"><span class="sr-only">Loading...</span></div>: statistics?.patientsToday?.antigen?.count}</h5>
                                      <p class="card-text float-right">
                                        <small class="text-muted">{statistics?.patientsToday?.antigen?.money} KM</small>
                                      </p>
                                    </div>
                                  </div>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        </Col>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </ModalBody>
            </Modal>

            {/* KRAJ MODALA */}
            <Modal
              isOpen={modal}
              toggle={toggle}
            >
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Editovanje resellera" : "Kreiranje resellera"}
            </ModalHeader>
            <ModalBody>
              <AvForm
                onSubmit={
                  handleValidSubmit
                }
              >
                <Row form>
                  <Col xs={12}>
                    <div className="mb-3">
                      <FormGroup>
                        <AvField
                          name="name"
                          label="Naziv objekta"
                          className="form-control"
                          placeholder="Unesite naziv objekta"
                          type="text"
                          value={user?.name || ""}
                          invalid={errors?.name}
                          required
                        />
                        {errors?.name && <FormFeedback className={"d-block ".concat(errors?.name ? "visible" : "invisible")}>{errors?.name}</FormFeedback>}
                      </FormGroup>
                    </div>
                    <div className="mb-3">
                      <FormGroup>
                        <AvField
                          name="username"
                          label="Korisničko ime"
                          className="form-control"
                          placeholder="Unesite korisničko ime"
                          type="text"
                          value={user?.username || ""}
                          invalid={errors?.username}
                          required
                        />
                        {errors?.username && <FormFeedback className={"d-block ".concat(errors?.username ? "visible" : "invisible")}>{errors?.username}</FormFeedback>}
                      </FormGroup>
                    </div>
                    <div className="mb-3">
                      <FormGroup>
                        <AvField
                          name="email"
                          label="Email"
                          className="form-control"
                          placeholder="Unesite email"
                          type="email"
                          value={user?.email || ""}
                          invalid={errors?.email}
                          required
                        />
                        {errors?.email && <FormFeedback className={"d-block ".concat(errors?.email ? "visible" : "invisible")}>{errors?.email}</FormFeedback>}
                      </FormGroup>
                    </div>
                    <div className="mb-3">
                      <FormGroup>
                        <AvField
                          name="phone"
                          label="Broj telefona"
                          className="form-control"
                          placeholder="Unesite broj telefona"
                          type="text"
                          value={user?.phone|| ""}
                          invalid={errors?.phone}
                          required
                        />
                        {errors?.phone && <FormFeedback className={"d-block ".concat(errors?.phone ? "visible" : "invisible")}>{errors?.phone}</FormFeedback>}
                      </FormGroup>
                    </div>
                    <div className="mb-3">
                      <FormGroup>
                        <AvField
                          name="address"
                          label="Adresa"
                          className="form-control"
                          placeholder="Unesite adresu"
                          type="text"
                          value={user?.additional_info?.address || ""}
                          invalid={errors?.address}
                          required
                        />
                        {errors?.address && <FormFeedback className={"d-block ".concat(errors?.address ? "visible" : "invisible")}>{errors?.address}</FormFeedback>}
                      </FormGroup>
                    </div>
                    <div className="mb-3">
                      <FormGroup>
                        <AvField
                          name="pdv_number"
                          label="PDV broj"
                          className="form-control"
                          placeholder="Unesite pdv broj"
                          type="text"
                          value={user?.additional_info?.pdv_number || ""}
                          invalid={errors?.pdv_number}
                        />
                        {errors?.pdv_number && <FormFeedback className={"d-block ".concat(errors?.pdv_number ? "visible" : "invisible")}>{errors?.pdv_number}</FormFeedback>}
                      </FormGroup>
                    </div>
                    <div className="mb-3">
                      <FormGroup>
                        <AvField
                          name="id_number"
                          label="Identifikacijski broj"
                          className="form-control"
                          placeholder="Unesite id broj"
                          type="text"
                          value={user?.additional_info?.id_number || ""}
                          invalid={errors?.id_number}
                        />
                        {errors?.id_number && <FormFeedback className={"d-block ".concat(errors?.id_number ? "visible" : "invisible")}>{errors?.id_number}</FormFeedback>}
                      </FormGroup>
                    </div>
                    <div className="mb-3">
                      <FormGroup>
                        <AvField
                          name="password"
                          label="Šifra"
                          className="form-control"
                          placeholder="Unesite šifru"
                          type="text"
                          value={user?.password || ""}
                          invalid={errors?.password}
                          required
                        />
                        {errors?.password && <FormFeedback className={"d-block ".concat(errors?.password ? "visible" : "invisible")}>{errors?.password}</FormFeedback>}
                      </FormGroup>
                    </div>
                    <div className="mb-3">
                        <div className="form-check">
                          <Input
                            type="checkbox"
                            className="form-check-Input"
                            name="hotelRole"
                            id="hotelRole"
                            checked={hotelChecked}
                            onChange={(e) => setHotelChecked(!hotelChecked)} 
                          />
                          <Label
                            className="form-check-Label"
                            htmlFor="samplingType"
                          >
                            Hotel permisija
                          </Label>
                        </div>
                      </div>
                    <div className="mb-3">
                      <FormGroup>
                        <AvField
                          name="avatar"
                          label="Avatar"
                          className="form-control"
                          placeholder="Izaberite sliku"
                          type="file"
                          onChange={(e) => setAvatar(e.target.files[0])}
                          invalid={errors?.avatar}
                          required
                        />
                        {errors?.avatar && <FormFeedback className={"d-block ".concat(errors?.avatar ? "visible" : "invisible")}>{errors?.avatar}</FormFeedback>}
                      </FormGroup>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                      >Spremi</button>
                    </div>
                  </Col>
                </Row>
              </AvForm>
            </ModalBody>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

export {Resellers as AdminResellers}
