import React, {useState, useRef, useEffect} from "react"
import { Link, useHistory, useParams, useLocation } from "react-router-dom"
import { Col, Container, Form, FormFeedback, FormGroup, Label, Row, Input, Dropdown,DropdownMenu,DropdownItem,DropdownToggle,ButtonDropdown } from "reactstrap"

import { AvForm, AvField } from "availity-reactstrap-validation"
import { useDispatch, useSelector } from "react-redux";
import { register, userSelector, clearState } from "../../slices/UserSlice";
// import images
import CarouselPage from "./CarouselPage"
import { useTranslation } from 'react-i18next';
import ReactInputDateMask from "../../components/ReactInputDateMask";

import * as c from "../../app/config";
import queryString from 'query-string';
import axios from "axios"

const Register = () => {
  const { search } = useLocation();
  let { resellerUsername } = useParams();

  const { t, i18n } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { loading, error, errors } = useSelector(userSelector);
  const [ birthDate, setBirthDate ] = useState(c.getCurrentDate());
  const [ sex, setSex ] = useState('');
  const [ languageBtn, setLanguageBtn ] = useState(false)
  const [ checked, setChecked ] = useState(false);

  const formEl = useRef(null)
  const handleSignIn = (event, errors, values) => {
    event.preventDefault();
    //formEl.current.reset();

    values['birthDate'] = birthDate;
    values['sex'] = sex;
    values['resellerUsername'] = resellerUsername || "";
    //poziv
    dispatch(register(values, formEl));
  };


  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  }

  const [ languageOpen, setLanguageOpen ] = useState(false);
  const onClick = () => setLanguageOpen(!languageOpen);
  

  const [ logoUrl, setLogoUrl ] = useState(null);
  const [ sffBrojKarte, showSffBrojKarte ] = useState(false);

  useEffect(() => {
    const values = queryString.parse(search);
    if(values.lang) changeLanguage(values.lang);

    if(resellerUsername == "triptobosnia") changeLanguage('ar');
    if(resellerUsername == "sff") showSffBrojKarte(true);

    if(resellerUsername){
      axios.get('https://api.labos.ba/api/avatar/'+resellerUsername).then((response) => {
        setLogoUrl("https://api.labos.ba/api/"+response.data.avatar);
      })
      .catch((error) => {
       
      });
    }

  }, []);

  const handleChange = () => {
    setChecked(!checked)
  }

  return (
    <React.Fragment>
       <div>
          <Container fluid className="p-0">
            <Row className="g-0">
              <CarouselPage />
              <Col xl={3}>
                <div className="auth-full-page-content p-md-5 p-4">
                  <div className="w-100">
                    <div className="d-flex justify-content-center">
                      {logoUrl != null && <img style={{width: "100px"}} src={logoUrl} />}
                    </div>
                    <div className="d-flex flex-column h-100">
                      <div className="my-auto">
                        <div className="d-flex flex-row justify-content-between">
                          <h5 className="text-primary">{t('registration')}</h5>
                          <Dropdown
                            isOpen={languageBtn}
                            toggle={() => setLanguageBtn(!languageBtn)}
                          >
                            <DropdownToggle tag="button" className="btn btn-primary">
                              <i className="bx bx-globe" />
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem onClick={() => changeLanguage("ba")}>Bosnian</DropdownItem>
                              <DropdownItem onClick={() => changeLanguage("en")}>English</DropdownItem>
                              <DropdownItem onClick={() => changeLanguage("ar")}>Arabic</DropdownItem>
                            </DropdownMenu>
                          </Dropdown>
                        </div>
                        <div className="mt-4">
                          <AvForm className="form-horizontal" onSubmit={handleSignIn} ref={formEl}>
                            <div className="mb-3">
                              <FormGroup>
                                <AvField
                                  name="firstname"
                                  label={t('firstname')}
                                  className="form-control"
                                  placeholder={t('enter_firstname')}
                                  type="text"
                                  errorMessage={t('enter_firstname')}
                                  invalid={errors?.firstname}
                                />
                                {errors?.firstname && <FormFeedback className={"d-block ".concat(errors?.firstname ? "visible" : "invisible")}>{errors?.firstname}</FormFeedback>}
                              </FormGroup>
                            </div>
                            <div className="mb-3">
                              <FormGroup>
                                <AvField
                                  name="lastname"
                                  label={t('lastname')}
                                  className="form-control"
                                  placeholder={t('enter_lastname')}
                                  type="text"
                                  errorMessage={t('enter_lastname')}
                                  invalid={errors?.lastname}
                                />
                                {errors?.lastname && <FormFeedback className={"d-block ".concat(errors?.lastname ? "visible" : "invisible")}>{errors?.lastname}</FormFeedback>}
                              </FormGroup>
                            </div>
                            <div className="mb-3">
                              <FormGroup>
                                <AvField
                                  name="email"
                                  label={t('enter_email')}
                                  className="form-control"
                                  placeholder={t('enter_email')}
                                  type="email"
                                  errorMessage={t('enter_email')}
                                  invalid={errors?.email}
                                />
                                {errors?.email && <FormFeedback className={"d-block ".concat(errors?.email ? "visible" : "invisible")}>{errors?.email}</FormFeedback>}
                              </FormGroup>
                            </div>
                            <div className="mb-3">
                              <FormGroup>
                                <AvField
                                  name="phone"
                                  label={t('phone')}
                                  className="form-control"
                                  placeholder={t('enter_phone')}
                                  type="text"
                                  errorMessage={t('enter_phone')}
                                  invalid={errors?.phone}
                                />
                                {errors?.phone && <FormFeedback className={"d-block ".concat(errors?.phone ? "visible" : "invisible")}>{errors?.phone}</FormFeedback>}
                              </FormGroup>
                            </div>
                            <div className="mb-3">
                              <FormGroup>
                                <AvField
                                  name="jmbg"
                                  label={t('enter_jmbg')}
                                  className="form-control"
                                  placeholder={t('enter_jmbg')}
                                  type="text"
                                  errorMessage={t('enter_jmbg')}
                                  inval id={errors?.jmbg}
                                />
                                {errors?.jmbg && <FormFeedback className={"d-block ".concat(errors?.jmbg ? "visible" : "invisible")}>{errors?.jmbg}</FormFeedback>}
                              </FormGroup>
                            </div>
                            <div className="mb-3">
                              <FormGroup>
                                <label>
                                  {t('birthDate')}
                                </label>
                                <ReactInputDateMask
                                  mask='dd.mm.yyyy'
                                  showMaskOnFocus={false}
                                  className="form-control"
                                  value={birthDate}
                                  onChange={setBirthDate}
                                  showMaskOnHover={true}
                                />                            
                                {errors?.birthDate && <FormFeedback className={"d-block ".concat(errors?.birthDate ? "visible" : "invisible")}>{errors?.birthDate}</FormFeedback>}
                              </FormGroup>
                            </div>
                            {sffBrojKarte && 
                              <div className="mb-3">
                                <FormGroup>
                                  <AvField
                                    name="brojKarte"
                                    label={t('enter_ticketNumberww')}
                                    className="form-control"
                                    placeholder={t('enter_ticketNumber')}
                                    type="text"
                                    errorMessage={t('enter_ticketNumber')}
                                    invalid={errors?.brojKarte}
                                  />
                                  {errors?.brojKarte && <FormFeedback className={"d-block ".concat(errors?.brojKarte ? "visible" : "invisible")}>{errors?.brojKarte}</FormFeedback>}
                                </FormGroup>
                              </div>
                            } 
                            <div className="mb-3">
                              <label>
                                {t('gender')}
                              </label>
                              <div className="d-flex flex-row">
                                <div>
                                  <input 
                                    className="hidden" 
                                    type="radio" 
                                    id="male" 
                                    value="MUŠKI"
                                    name="gender" 
                                    checked={sex === "MUŠKI"}
                                    onChange={(e) => setSex(e.target.value)}
                                  />
                                  <label
                                    className={"hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 cursor-pointer rounded-l "+(sex === "MUŠKI" ? "bg-gray-400": "bg-gray-300")} 
                                    htmlFor="male">
                                      {t('male')}
                                  </label>
                                </div>
                                <div>
                                  <input 
                                    className="hidden" 
                                    type="radio" 
                                    id="female" 
                                    value="ŽENSKI" 
                                    name="gender"
                                    checked={sex === "ŽENSKI"}
                                    onChange={(e) => setSex(e.target.value)}
                                  />
                                  <label 
                                    className={"hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 cursor-pointer rounded-l "+(sex === "ŽENSKI" ? "bg-gray-400": "bg-gray-300")} 
                                    htmlFor="female">
                                      {t('female')}
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="mt-4 text-center">
                              <div className="form-check">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="customControlInline"
                                  checked={checked}
                                  onChange={handleChange}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="customControlInline"
                                >
                                  <p className="mb-0">
                                    Slažem se sa{" "}
                                    <Link  to={{ pathname: "https://labos.ba/tos" }} target="_blank" className="text-primary">
                                      uslovima korištenja
                                    </Link>
                                  </p>
                                </label>
                              </div>
                            </div>
                            <div className="mt-3 d-grid">
                              <button
                                className="btn btn-primary btn-block "
                                type="submit"
                                disabled={!checked}
                              >
                                {loading ? t('loading'): t('submit')}
                              </button>
                            </div>
                          </AvForm>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
    </React.Fragment>
  )
}

export default Register;
