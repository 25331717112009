import { createSlice } from "@reduxjs/toolkit";
import {toastr} from 'react-redux-toastr';
import axios from "axios";
import * as c from "../app/config";
import moment from 'moment'

/* RESULTS */
export function getPatients() {
  return async (dispatch) => {
    dispatch(setLoading());
    axios({
      method: "get",
      url: `${c.API_URL}/admin/patients`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    })
    .then((response) => {
      dispatch(setPatients(response.data.data));
    })
    .catch((error) => {
      dispatch(setError(error.response.data.errors));
      toastr.error('Greška', error.response.data.message)
    });
  };
}

export function getResellers() {
  return async (dispatch) => {
    dispatch(setLoading());
    axios({
      method: "get",
      url: `${c.API_URL}/admin/resellers`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    })
    .then((response) => {
      dispatch(setResellers(response.data.data));
    })
    .catch((error) => {
      dispatch(setError(error.response.data.errors));
      toastr.error('Greška', error.response.data.message)
    });
  };
}

export function getSamplings() {
  return async (dispatch) => {
    dispatch(setLoading());
    axios({
      method: "get",
      url: `${c.API_URL}/admin/samplings`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    })
    .then((response) => {
      dispatch(setSamplings(response.data.data));
    })
    .catch((error) => {
      dispatch(setError(error.response.data.errors));
      toastr.error('Greška', error.response.data.message)
    });
  };
}

export function getActivities() {
  return async (dispatch) => {
    dispatch(setLoading());
    axios({
      method: "get",
      url: `${c.API_URL}/admin/patients/activities`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    })
    .then((response) => {
      dispatch(setActivities(response.data.data));
    })
    .catch((error) => {
      dispatch(setError(error.response.data.errors));
      toastr.error('Greška', error.response.data.message)
    });
  };
}

export function getResellerStatistics(payload) {
  return async (dispatch) => {
    axios({
      method: "get",
      url: `${c.API_URL}/admin/resellers/${payload}/statistics`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    })
    .then((response) => {
      dispatch(setResellerStatistic(response.data.data));
    })
    .catch((error) => {
      dispatch(setError(error.response.data.errors));
      toastr.error('Greška', error.response.data.message)
    });
  };
}

export function getUpcomingBirthdays(payload) {
  return async (dispatch) => {
    dispatch(setUpcomingBirthdaysLoading());
    axios({
      method: "get",
      url: `${c.API_URL}/admin/upcoming-birthdays`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    })
    .then((response) => {
      dispatch(setUpcomingBirthdays(response.data.data));
    })
    .catch((error) => {
      dispatch(setError(error.response.data.errors));
      toastr.error('Greška', error.response.data.message)
    });
  };
}

export function editSamplingStatus(payload) {
  return async (dispatch) => {
    //dispatch(setLoading());
    axios({
      method: "post",
      url: `${c.API_URL}/admin/samplings/${payload.id}/status`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
      data: payload.data
    })
    .then((response) => {
      toastr.success('Uspješno', response.data.message);
      dispatch(editSampling(response.data.data));
    })
    .catch((error) => {
      dispatch(setError(error.response.data.errors));
      toastr.error('Greška', error.response.data.message)
    });
  };
}

export function createReseller(payload, toggle) {
  return async (dispatch) => {
    dispatch(setLoading());

    var form_data = new FormData();
    for ( var key in payload ) {
        form_data.append(key, payload[key]);
    }

    axios({
      method: "post",
      url: `${c.API_URL}/admin/resellers`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        'Content-Type': 'multipart/form-data'
      },
      data: form_data
    })
    .then((response) => {
      toastr.success('Uspješno!', response.data.message);
      dispatch(addReseller(response.data.data))
      dispatch(disableLoading());
      toggle(); // gasi modal
    })
    .catch((error) => {
      if(error.response?.data?.message != "The given data was invalid."){
        toastr.error('Greška', error.response.data.message)
      }
      dispatch(setError(error.response.data.errors));
      dispatch(disableLoading());
    });
  };
}

export function updateReseller(payload, toggle) {
  return async (dispatch) => {
    dispatch(setLoading());

    var form_data = new FormData();
    for ( var key in payload ) {
        form_data.append(key, payload[key]);
    }

    axios({
      method: "post",
      url: `${c.API_URL}/admin/resellers/${payload.id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        'Content-Type': 'multipart/form-data'
      },
      data: form_data
    })
    .then((response) => {
      toastr.success('Uspješno!', response.data.message);
      dispatch(editReseller(response.data.data))
      dispatch(disableLoading());
      toggle(); // gasi modal
    })
    .catch((error) => {
      console.log(error);
      if(error.response?.data?.message != "The given data was invalid."){
        toastr.error('Greška', error.response.data.message)
      }
      dispatch(setError(error.response.data.errors));
      dispatch(disableLoading());
    });
  };
}

export function updatePatient(payload, toggle) {
  return async (dispatch) => {
    dispatch(setLoading());
    axios({
      method: "post",
      url: `${c.API_URL}/admin/patients/${payload.id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        'Content-Type': 'application/json'
      },
      data: payload
    })
    .then((response) => {
      toastr.success('Uspješno!', response.data.message);
      dispatch(editPatient(response.data.data))
      dispatch(disableLoading());
      toggle(); // gasi modal
    })
    .catch((error) => {
      console.log(error);
      if(error.response?.data?.message != "The given data was invalid."){
        toastr.error('Greška', error.response.data.message)
      }
      dispatch(setError(error.response.data.errors));
      dispatch(disableLoading());
    });
  };
}

export function deletePatient(payload, toggleDeleteModal) {
  return async (dispatch) => {
    dispatch(setLoading());
    axios({
      method: "delete",
      url: `${c.API_URL}/admin/patients/${payload}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        'Content-Type': 'application/json'
      },
    })
    .then((response) => {
      toastr.success('Uspješno!', response.data.message);
      dispatch(removePatient(payload))
      dispatch(disableLoading());
      toggleDeleteModal(); // gasi modal
    })
    .catch((error) => {
      console.log(error);
      if(error.response?.data?.message != "The given data was invalid."){
        toastr.error('Greška', error.response.data.message)
      }
      dispatch(setError(error.response.data.errors));
      dispatch(disableLoading());
    });
  };
}


export function makeTransfer(payload) {
  return async (dispatch) => {
    //dispatch(clearState());
    //dispatch(setLoading());
    axios({
      method: "post",
      url: `${c.API_URL}/admin/users/${payload}/transfer`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    })
    .then((response) => {
      toastr.success('Uspješno!', response.data.message);
      dispatch(editPatient(response.data.data));
    })
    .catch((error) => {
      dispatch(setError(error.response.data.errors));
      toastr.error('Greška', error.response.data.message)
    });
  };
}

export function getAllAppointments2() {
  return async (dispatch) => {
    dispatch(setLoading());
    axios({
      method: "get",
      url: `${c.API_URL}/admin/appointments`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    })
    .then((response) => {
      response.data.data.map((res, i) => {
        /* var types = '';

        response.data.data[i].sampling.types.map((type, i) => {
          types += " "+type.name;
        }) */
        response.data.data[i].start = moment(response.data.data[i].start).toDate();
        response.data.data[i].end = moment(response.data.data[i].end).toDate();
        response.data.data[i].title = response.data.data[i].info.firstName + ' ' + response.data.data[i].info.lastName 
          + ' | ' + response.data.data[i].test.name
          + ' | ' + response.data.data[i].title;
      });

      dispatch(setAppointments(response.data.data));
    })
    .catch((error) => {
      console.log(error);
    //  dispatch(setError(error.response.data.errors));
      //toastr.error('Greška', error.response.data.message)
    });
  };
}

export function getAllWorkingTimes(payload) {
  return async (dispatch) => {
    axios({
      method: "get",
      url: `${c.API_URL}/appointments/workingtimes`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      }
    })
    .then((response) => {
      dispatch(setWorkingTimes(response.data.data));
    })
    .catch((error) => {
      dispatch(setError(error.response.data.errors));
      toastr.error('Greška', error.response.data.message)
    });
  };
}

export function markAppointment(payload, toogleInfo) {
  return async (dispatch) => {
    axios({
      method: "post",
      url: `${c.API_URL}/admin/appointments/${payload}/success`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      }
    })
    .then((response) => {
      toastr.success('Uspješno', response.data.message);
      dispatch(getAllAppointments2());
      toogleInfo()
    })
    .catch((error) => {
      dispatch(setError(error.response.data.errors));
      toastr.error('Greška', error.response.data.message)
    });
  };
}

export function cancelAppointment(payload, toogleInfo) {
  return async (dispatch) => {
    axios({
      method: "post",
      url: `${c.API_URL}/admin/appointments/${payload}/cancel`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      }
    })
    .then((response) => {
      toastr.success('Uspješno', response.data.message);
      dispatch(getAllAppointments2());
      toogleInfo()
    })
    .catch((error) => {
      dispatch(setError(error.response.data.errors));
      toastr.error('Greška', error.response.data.message)
    });
  };
}

export function updateGlobalWt(payload) {
  return async (dispatch) => {
    axios({
      method: "post",
      url: `${c.API_URL}/appointments/workingtimes/global`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
      data: payload
    })
    .then((response) => {
      toastr.success('Uspješno', response.data.message);
      dispatch(editGlobalWt(response.data.data));
    })
    .catch((error) => {
      dispatch(setError(error.response.data.errors));
      toastr.error('Greška', error.response.data.message)
    });
  };
}

export function updateSeparateWt(payload) {
  return async (dispatch) => {
    axios({
      method: "post",
      url: `${c.API_URL}/appointments/workingtimes/separate`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
      data: payload
    })
    .then((response) => {
      toastr.success('Uspješno', response.data.message);
      //dispatch(editGlobalWt(response.data.data));
    })
    .catch((error) => {
      dispatch(setError(error.response.data.errors));
      toastr.error('Greška', error.response.data.message)
    });
  };
}

export const adminSlice = createSlice({
  name: 'admin',
  initialState: {
    loading: false,
    error: false,
    errors: '',
    patients: [],
    statistic: [],
    resellers: [],
    samplings: [],
    activities: [],
    filter: null,
    upcomingBirthdays: [],
    upcomingBirthdaysLoading: false,
    appointments: [],
    workingTimes: []
  },
  reducers: {
    clearState: (state) => {
      state.loading = false;
      state.errors = '';
      state.error = false;
      state.filter = [];
      state.patients = [];
      state.statistic = [];
      state.samplings = [];
      state.activities = [];
      state.statistics = [];
      state.statisticLoading = false;
      state.upcomingBirthdays = [];
      state.upcomingBirthdaysLoading = false;
      state.appointments = [];
      state.workingTimes = [];
      return state;
    },
    setLoading: (state) => {
      state.loading = true;
    },
    setStatisticLoading: (state) => {
      state.statisticLoading = true;
    },
    setUpcomingBirthdaysLoading: (state) => {
      state.upcomingBirthdaysLoading = true;
    },
    setError: (state, {payload}) =>{
      state.error = true;
      state.errors = payload;
      state.loading = false;
    },
    clearErrors: (state, {payload}) => {
      state.error = false;
      state.errors = '';
    },
    disableLoading: (state) => {
      state.loading = false;
    },
    setPatients: (state, {payload}) => {
      state.patients = payload.patients;
      state.statistic = payload.statistic;
      state.loading = false;
    },
    setPatientsResults: (state, {payload}) => {
      state.results = payload;
      state.loading = false;
    },
    setResellers: (state, {payload}) => {
      state.resellers = payload;
      state.loading = false;
    },
    setSamplings: (state, {payload}) => {
      state.samplings = payload;
      state.loading = false;
    },
    getResellerByid: (state, {payload}) => {
      state.filter = [];
      var filter = state.resellers.filter(item => item.id == payload.id);
      state.filter = filter;
    },
    addReseller: (state, {payload}) => {
      state.resellers.push(payload);
    },
    editReseller: (state, {payload}) =>{
      let index = state.resellers.findIndex(item => item.id == payload.id);
      state.resellers[index] = payload;
    },
    editPatient: (state, {payload}) =>{
      let index = state.patients.findIndex(item => item.id == payload.id);
      state.patients[index] = payload;
    },
    editSampling: (state, {payload}) => {
      let index = state.samplings.findIndex(item => item.id == payload.id);
      state.samplings[index] = payload;
    },
    removePatient: (state, {payload}) => {
      state.patients = state.patients.filter(item => item.id != payload)
    },
    setActivities: (state, {payload}) => {
      state.activities = payload;
      state.loading = false;
    },
    setResellerStatistic: (state, {payload}) => {
      state.statistics = [];
      state.statistics = payload;
      state.statisticLoading = false;
    },
    setUpcomingBirthdays: (state, {payload}) => {
      state.upcomingBirthdays = payload;
      state.upcomingBirthdaysLoading = false;
    },
    setAppointments: (state, {payload}) => {
      state.appointments = payload;
      state.loading = false;
    },
    addAppointment: (state, {payload}) => {
      state.appointments.push(payload);
    },
    editGlobalWt: (state, {payload}) => {
      state.workingTimes.global = payload
    },
    setWorkingTimes: (state, {payload}) => {
      state.workingTimes = payload
    }
   },
});

export const { editGlobalWt, setWorkingTimes, setAppointments, addAppointment, setUpcomingBirthdaysLoading, setUpcomingBirthdays, setStatisticLoading, setResellerStatistic, setActivities, setSamplings, removePatient, editSampling, clearState, editReseller, editPatient, setLoading, setError, clearErrors, disableLoading, setPatients, setResellers, addReseller, getResellerByid } = adminSlice.actions;
export const adminSelector = (state) => state.admin;
export const adminReducer = adminSlice.reducer;