import { createSlice } from "@reduxjs/toolkit";
import {toastr} from 'react-redux-toastr';
import axios from "axios";
import * as c from "../app/config";
import jwt from "jsonwebtoken";
import moment from 'moment'
export const isValidToken = (token) => {
  let decoded = jwt.decode(token);
  return new Date(decoded.exp * 1000) > new Date() ? decoded : null;
};

export function me() {
  return async (dispatch) => {
    dispatch(setLoading());
    axios({
      method: "get",
      url: `${c.API_URL}/me`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    })
    .then((response) => {
      dispatch(setData(response.data.data));
      localStorage.setItem("avatarUrl", response.data.data.avatar);
      dispatch(disableLoading());
    })
    .catch((error) => {
      dispatch(setError(error.response.data.errors));
      toastr.error('Greška', error.response.data.message)
    });
  };
}

/* LOGIN */
export function login(payload, history) {
  return async (dispatch) => {
    dispatch(setLoading());
    axios({
      method: "post",
      url: `${c.API_URL}/auth/login`,
      data: payload
    })
    .then((response) => {
      console.log(response);
      const { accessToken, info } = response.data.data;
      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("roles", JSON.stringify(info.roles));
      dispatch(setToken(accessToken));
      dispatch(setData(info));
      dispatch(setRoles(info.roles));
      dispatch(disableLoading());

      setTimeout(function(){ 
        history.push("/user/home"); 
      }, 1000);
    })
    .catch((error) => {
      dispatch(setError(error.response?.data?.errors));
      if(error.response?.data?.message != "The given data was invalid."){
        toastr.error('Greška', error.response.data.message)
      }
    });
  };
}

/* FORGOT PASSWORD */
export function forgotPassword(payload) {
  return async (dispatch) => {
    dispatch(setLoading());
    axios({
      method: "post",
      url: `${c.API_URL}/auth/password/forgot`,
      data: payload
    })
    .then((response) => {
      toastr.success('Uspješno!', response.data.message);
      dispatch(disableLoading());
    })
    .catch((error) => {
      dispatch(setError(error.response.data.errors));
      if(error.response?.data?.message != "The given data was invalid."){
        toastr.error('Greška', error.response.data.message)
      }
    });
  };
}

/* RESET PASSWORD */
export function resetPassword(payload) {
  return async (dispatch) => {
    dispatch(setLoading());
    axios({
      method: "post",
      url: `${c.API_URL}/auth/password/reset`,
      data: payload
    })
    .then((response) => {
      toastr.success('Uspješno', response.data.message);
      dispatch(disableLoading());
    })
    .catch((error) => {
      dispatch(setError(error.response.data.errors));
      if(error.response?.data?.message != "The given data was invalid."){
        toastr.error('Greška', error.response.data.message)
      }
    });
  };
}

/* RESULTS */
export function getResults() {
  return async (dispatch) => {
    dispatch(setLoading());
    axios({
      method: "get",
      url: `${c.API_URL}/me/results`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    })
    .then((response) => {
      dispatch(setResults(response.data.data));
    })
    .catch((error) => {
      dispatch(setError(error.response.data.errors));
      toastr.error('Greška', error.response.data.message)
    });
  };
}

export function getAllResults() {
  return async (dispatch) => {
    dispatch(setLoading());
    axios({
      method: "get",
      url: `${c.API_URL}/me/results/all`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    })
    .then((response) => {
      dispatch(setNotGroupedResults(response.data.data));
    })
    .catch((error) => {
      dispatch(setError(error.response.data.errors));
      toastr.error('Greška', error.response.data.message)
    });
  };
}

export function changePassword(payload) {
  return async (dispatch) => {
    dispatch(setLoading());
    axios({
      method: "post",
      url: `${c.API_URL}/me/changePassword`,
      data: payload,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        'Content-Type': 'application/json'
      }
    })
    .then((response) => {
      toastr.success('Uspješno', response.data.message);
      dispatch(disableLoading());
      dispatch(clearState());
    })
    .catch((error) => {
      if(error.response?.data?.message != "The given data was invalid."){
        toastr.error('Greška', error.response.data.message)
      }
      
      if(error.response.data.message === "PASSWORD_INCORRECT"){
        error.response.data.errors = error.response.data.data.errors;
      }

      dispatch(setError(error.response.data.errors));
    });
  };
}

export function register(payload, formEl) {
  return async (dispatch) => {
    dispatch(setLoading());
    axios({
      method: "post",
      url: `${c.API_URL}/auth/patient/register`,
      data: payload
    })
    .then((response) => {
      toastr.success('Uspješno', response.data.message, {timeOut: 10000});
      dispatch(disableLoading());
      dispatch(clearState());
      formEl.current.reset();
    })
    .catch((error) => {
      dispatch(setError(error.response.data.errors));
      if(error.response?.data?.message != "The given data was invalid."){
        toastr.error('Greška', error.response.data.message)
      }
    });
  };
}

export function registerCalendar(payload, toogle, toggleTab) {
  return async (dispatch) => {
    dispatch(setLoading());
    axios({
      method: "post",
      url: `${c.API_URL}/auth/appointment/register`,
      data: payload
    })
    .then((response) => {
      toastr.success('Uspješno', [], {timeOut: 10000});
      dispatch(disableLoading());
      dispatch(clearState());
      if(toogle) toogle()
      if(toggleTab) toggleTab(4, true)
    })
    .catch((error) => {
      dispatch(setError(error.response.data.errors));
      if(error.response?.data?.message == "RESERVED"){
        toastr.error('Greška', 'Odabrani termin je već zauzet!')
      }else if(error.response?.data?.message == "INVALID_GIFT"){
        toastr.error('Greška', 'Link poklona je iskorišten ili je istekao!')
      }
    });
  };
}

export function getAllAppointments() {
  return async (dispatch) => {
    dispatch(setLoading());
    axios({
      method: "get",
      url: `${c.API_URL}/appointments/slots`,
    })
    .then((response) => {
      /* response.data.data.map((res, i) => {
        response.data.data[i].start = moment(response.data.data[i].start).toDate();
        response.data.data[i].end = moment(response.data.data[i].end).toDate();
      }); */



      dispatch(setSlots(response.data.data));
    })
    .catch((error) => {
    //  dispatch(setError(error.response.data.errors));
      //toastr.error('Greška', error.response.data.message)
    });
  };
}

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    accessToken: localStorage.getItem("accessToken")? isValidToken(localStorage.getItem("accessToken")): null,
    data: {},
    roles: localStorage.getItem("roles")? (localStorage.getItem("roles")): [],
    loading: false,
    error: false,
    errors: '',
    filter: {},
    results: [],
    setNotGroupedResults: {},
    slots: []
  },
  reducers: {
    clearState: (state) => {
      state.loading = false;
      state.errors = '';
      state.error = false;
      state.filter = {};
      return state;
    },
    setLoading: (state) => {
      state.loading = true;
    },
    setToken: (state, {payload}) => {
      state.loading = false;
      state.error = false;
      state.errors = false;
      state.accessToken = payload;
    },
    setData: (state, {payload}) => {
      state.data = payload;
    },
    setRoles: (state, {payload}) => {
      state.roles = payload;
    },
    setError: (state, {payload}) =>{
      state.error = true;
      state.errors = payload;
      state.loading = false;
    },
    disableLoading: (state) => {
      state.loading = false;
    },
    setResults: (state, {payload}) => {
      state.results = payload;
      state.loading = false;
    },
    setNotGroupedResults: (state, {payload}) => {
      state.resultsAll = payload;
      state.loading = false;
    },
    setSlots: (state, {payload}) => {
      state.slots = payload;
      state.loading = false;
    },
    addSlot: (state, {payload}) => {
      state.slots.push(payload);
    },
  },
});

export const { addSlot, setSlots, clearState, setLoading, setToken, setResults, setNotGroupedResults, setError, setRoles, disableLoading, setData, setFilter } = userSlice.actions;
export const userSelector = (state) => state.user;
export const userReducer = userSlice.reducer;