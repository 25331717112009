import React, {useState, useRef, useEffect } from "react"
import { Link, useHistory, useLocation } from "react-router-dom"
import { ButtonGroup, NavItem, Table, NavLink, Button, Media, Progress, TabContent, TabPane, Row, Col, Card, CardBody, FormGroup, Form, FormFeedback, CardTitle, Input, Dropdown, DropdownMenu, DropdownItem, DropdownToggle, ButtonDropdown, Label, Container, Modal, ModalHeader, ModalBody } from "reactstrap"
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation"
import classnames from "classnames"
import {Helmet} from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { getAllAppointments, registerCalendar, userSelector, clearState } from "../../slices/UserSlice";
// import images
import CarouselPage from "./CarouselPage"
import moment from 'moment'
import queryString from 'query-string';
import axios from "axios";

import { useTranslation } from 'react-i18next';
import * as c from "../../app/config";

import "react-dates/initialize";
import { DayPickerSingleDateController, isInclusivelyBeforeDay, isInclusivelyAfterDay } from "react-dates";
import "../../assets/test.css";

import "react-dates/lib/css/_datepicker.css";

import { Responsive, useMediaQuery } from "react-responsive";
import SweetAlert from "react-bootstrap-sweetalert"
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'
import baFlag from '../../assets/images/flags/ba.png';
import enFlag from '../../assets/images/flags/en.png';
moment.locale('bs', {
  week: {
      dow: new Date().getDay(),
      doy: new Date().getDay(),
  },
});

const CalendarRegister = () => {
  const { search } = useLocation();
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { loading, error, errors, slots } = useSelector(userSelector);
  const types = [
    {
      id: "panel_prostata",
      name: 'Panel Prostata',
      price: "-Total PSA<br>-Free PSA<br>-racio<br>-pregled urina<br><i>Redovna cijena: <strike>45,00 KM</strike></i><br><i>Cijena <b>Panela prostate:</b> 20,00 KM</i><br><i>Nalaz gotov isti dan.</i>",
      time: '',
      visible: true,
      backgroundColor: 'transparent'
    },
    {
      id: "panel_za_muskarce",
      name: t('man_panel'),
      price: "-Kompletna krvna slika<br>-Sedimentacija<br>-Glukoza<br>-Urea<br>-Kreatinin<br>-Holesterol<br>-Trigliceridi<br>-AST<br>-ALT<br>-Total PSA<br>-Free PSA<br>-Ratio<br><i>Cijena <b>panela za muškarce:</b> 45,00 KM</i>",
      time: '',
      visible: true,
      backgroundColor: '#ebf2e8'
    },
    {
      id: "panel_za_zene",
      name: t('woman_panel'),
      price: "-Kompletna krvna slika<br>-Sedimentacija<br>-Glukoza<br>-Urea<br>-Kreatinin<br>-Holesterol<br>-Trigliceridi<br>-AST<br>-ALT<br>-TSH<br>-FT3<br>-FT4<br><i>Cijena <b>panela za žene:</b> 45,00 KM</i>",
      time: '',
      visible: true,
      backgroundColor: '#ebf2e8'
    },
    {
      id: "db94dfdd-4716-45db-9586-90e6b83d21b7",
      name: "SARS CoV-2 <b>Antigen</b>",
      price: t('antigen_price'),
      time: t('antigen_time'),
      visible: true,
      backgroundColor: 'transparent'
    },
    {
      id: "6ac5d4a4-5030-4db2-aedd-c526739b5fb4",
      name: "SARS CoV-2 <b>PCR</b>",
      price: t('pcr_price'),
      time: t('pcr_time'),
      visible: true,
      backgroundColor: 'transparent'
    },
    {
      id: "10df1383-2166-4f13-9377-408535c703a6",
      name: t('covid_panel'),
      price: t('covid_panel_price'),
      time: t('covid_panel_time'),
      visible: true,
      backgroundColor: 'transparent'
    },
    {
      id: "f1e418b5-1f82-4664-874d-99809699f04a",
      name: t('basic_health_panel'),
      price: t('basic_health_panel_price'),
      time: t('basic_health_panel_time'),
      visible: true,
      backgroundColor: 'transparent'
    },
    {
      id: "aab93275-4450-413a-ad5b-0c0721c9bc5f",
      name: t('comprehensive_health_panel'),
      price: t('comprehensive_health_panel_price'),
      time: t('comprehensive_health_panel_time'),
      visible: true,
      backgroundColor: 'transparent'
    },
    {
      id: "panel_stitne_zlijezde",
      name: 'Panel štitne žlijezde',
      price: "-TSH<br>-FT3<br>-FT4<br><i>Cijena <b>panela štitne žlijezde:</b> 25,00 KM</i><br><i>Nalaz gotov isti dan.</i>",
      time: '',
      visible: true,
      backgroundColor: 'transparent'
    },
    {
      id: "prosireni_panel_stitne_zlijezde",
      name: 'Prošireni panel štitne žlijezde',
      price: "-TSH<br>-FT3<br>-FT4<br>-anti-Tg<br>-anti-TPO<br><i>Cijena <b>proširenog panela štitne žlijezde:</b> 70,00 KM</i><br><i>Nalaz gotov isti dan.</i>",
      time: '',
      visible: true,
      backgroundColor: 'transparent'
    },
    {
      id: "dae17e88-4022-11ec-9356-0242ac130003",
      name: t('other_analyzes'),
      price: "",
      time: t('other_time'),
      visible: true,
      backgroundColor: 'transparent'
    },
    {
      id: "11195210-5d32-11ec-bf63-0242ac130002",
      name: 'Rođendanski panel',
      price: "<b>Analize:</b> <br />Kompletna krvna slika - 3 diff <br />Glukoza<br />Holesterol<br />Trigliceridi<br />Kreatinin<br />Željezo<br />AST<br />ALT<br />UREA<br />Sedimentacija I<br />Sedimentacija II<br />Regularna cijena: <b><strike>40,00</strike>KM</b><br /><p style='font-size: 13px'>Cijena sa popustom(50%): <b>20,00KM</b></p><p>***Ponuda vrijedi 7 dana***</p>",
      time: "",
      visible: false,
      backgroundColor: 'transparent'
    }
  ];

  /* wizard */
  const [activeTab, setactiveTab] = useState(1)
  const [progressTab, setprogressTab] = useState(1)
  const [isGift, setIsGift] = React.useState(false);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  }
  const getCurrentLng = () => i18n.language || window.localStorage.i18nextLng || '';

  const [ languageOpen, setLanguageOpen ] = useState(false);
  const onClick = () => setLanguageOpen(!languageOpen);
  

  function toggleTab(tab, ignore = false) {
    if(tab == 1 && disableFirstTab) return;
    if(activeTab == 4) return;
    if(ignore){
      setactiveTab(tab)
      setprogressTab(tab)
    }

    if(activeTab !== tab){
      if(tab <= progressTab){
        setactiveTab(tab)
        if(progressTab < tab){
          setprogressTab(tab)
        }
      }
    }
  }

  /* calendar */
  const [ languageBtn, setLanguageBtn ] = useState(false)

  const [date, setDate] = React.useState(moment());
  const [time, setTime] = React.useState();
  const [test, setTest] = React.useState();
  const [phone, setPhone] = React.useState();
  const [phoneError, setPhoneError] = React.useState(false);

  const [focused, setFocused] = React.useState(true);
  const [showInput, setShowInput] = React.useState(false);

  const minDate = moment().subtract(1, 'days')
  if(!isGift){
    var maxDate = moment().add(3, 'months').endOf('month')
  }else{
    var maxDate = moment().add(8, 'days').endOf('day')
  }
  const isOutsideRange = day => isInclusivelyBeforeDay(day, minDate) || isInclusivelyAfterDay(day, maxDate)

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
  const isLaptop = useMediaQuery({ query: '(max-width: 1800px)' })
  const isDekstop = useMediaQuery({ query: '(min-width: 1800px)' })

  const [availableSlots, setAvailableSlots] = React.useState([]);

  function onDateChange(date){
    if(moment("2022-01-01").isSame(moment(date).format('YYYY-MM-DD'))) return;
    if(moment("2022-03-01").isSame(moment(date).format('YYYY-MM-DD'))) return;

    setDate(date)
    setAvailableSlots(slots[moment(date).format('DD.MM.YYYY')])
  }

  function onTimeChange(time){
    setTime(time)
    toggleTab(3, true)
  }

  function onTestChange(index){
    setTest(types[index])
    toggleTab(2, true)
  }

  const [time_alert, setTime_alert] = useState(false)
  const [confirm_alert, setconfirm_alert] = useState(false)
  const [dolazak, setDolazak] = React.useState(false);
  const [gift_invalid, setGiftInvalid_alert] = React.useState(false);
  const [disableFirstTab, setDisableFirstTab] = React.useState(false);
  /* form */
  const formEl = useRef(null)
  const [is_success, setIsSuccess] = useState(false)
  const handleSubmit = (event, errors, values) => {
    values['test'] = test.id
    values['time'] = time
    values['phone'] = phone
    values['date'] = moment(date).format('YYYY-MM-DD')
    if(dolazak){
      values['address'] = {
        'street': values.street
      }
    }

    values['arrival_at_home'] = dolazak
    values['lang'] = getCurrentLng()

    let patronatsdienst = ["07", "08", "17", "18", "19"];
    if(dolazak){
      if(patronatsdienst.includes(values['time'].slice(0, 2))){
        setTime_alert(true)
        return;
      }
    }
  
    const query = queryString.parse(search);
    if(query.gift){
      values['gift'] = query.gift
    }

    if(!phone){
      setPhoneError(true)
    }else{
      setPhoneError(false)
      dispatch(registerCalendar(values, null, toggleTab));
    }
  };

  useEffect(() => {
    const values = queryString.parse(search);
    if(values.gift){
      // check gift
      axios({
        method: "get",
        url: `${c.API_URL}/checkBirthdayGift/${values.gift}`
      })
      .then((response) => {
        //onTestChange(3)
        setIsGift(true)
        //toggleTab(2)
        //setDisableFirstTab(true)
      })
      .catch((error) => {
        setGiftInvalid_alert(true)
      });
    }
    dispatch(getAllAppointments());
  }, [dispatch]);

  return (
    <div>
      {/*<div className="page-content">*/}
      <div>
        <Container fluid={true}>
        <Helmet>
            <meta charSet="utf-8" />
            <title>LABOS Laboratorij - Rezervacija termina</title>
            <meta name="description" content="LABOS Laboratorij - Brzo i jednostavno zakazivanje termina." />
          </Helmet>
          <Row>
            <Col lg="12" style={{marginTop: '25px'}}>
              <div className="text-center">
                <img src="https://i.imgur.com/nmJUHy0.png" style={{width: 260, height: 70}} />
              </div>
                <div className="my-auto">
                  <div className="d-flex flex-row justify-content-between">
                    <Dropdown
                      isOpen={languageBtn}
                      toggle={() => setLanguageBtn(!languageBtn)}
                    >
                      <DropdownToggle tag="button" className="btn btn-light">
                        {getCurrentLng() == 'ba' && <img style={{width: "20px"}} src={baFlag} />}
                        {getCurrentLng() == 'en' && <img style={{width: "20px"}} src={enFlag} />}

                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem onClick={() => changeLanguage("ba")}><img style={{width: "16px", marginTop: '-3px'}} src={baFlag} /> Bosnian</DropdownItem>
                        <DropdownItem onClick={() => changeLanguage("en")}><img style={{width: "16px", marginTop: '-3px'}} src={enFlag} /> English</DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </div>
                <h4 style={{marginTop: '25px'}} className="card-title mb-4">{(isGift && (activeTab != 1)) || !isGift ? t('appointment') : ''}</h4>
                  <div className="wizard clearfix">
                    {(isGift && activeTab == 1) && <p>Povodom tvog rođendana LABOS laboratorija je pripremila Rođendanski panel sa popustom od 50%.</p>}
                    {(isGift && (activeTab != 1)) || !isGift ?
                      <div className="steps clearfix mb-5">
                        <ul>
                          <NavItem
                            className={classnames({ current: activeTab === 1 })}>
                            <NavLink
                              className={classnames({ current: activeTab === 1 })}
                              onClick={() => {
                                toggleTab(1)
                              }}
                            >
                              <span className="number">1.</span>{" "}
                              {t('choose_test')}
                            </NavLink>
                          </NavItem>
                          <NavItem className={classnames({ current: activeTab === 2 })}>
                            <NavLink
                              className={classnames({ active: activeTab === 2 })}
                              onClick={() => {
                                toggleTab(2)
                              }}
                            >
                              <span className="number">02</span>{" "}
                              {t('choose_appointment')}
                            </NavLink>
                          </NavItem>
                          <NavItem className={classnames({ current: activeTab === 3 })}>
                            <NavLink
                              className={classnames({ active: activeTab === 3 })}
                              onClick={() => {
                                toggleTab(3)
                              }}
                            >
                              <span className="number">03</span>{" "}
                              {t('contact_information')}
                            </NavLink>
                          </NavItem>
                          <NavItem className={classnames({ current: activeTab === 4 })}>
                            <NavLink
                              className={classnames({ active: activeTab === 4 })}
                              onClick={() => {
                                toggleTab(4)
                              }}
                            >
                              <span className="number">04</span>{" "}
                              {t('confirm_appointment')}
                            </NavLink>
                          </NavItem>
                        </ul>
                      </div>
                      : null
                    }
                  <TabContent
                    activeTab={activeTab}
                  >
                    <TabPane tabId={1}>
                      {types.map((item, i) => 
                        (!isGift && item.visible) || (!item.visible && isGift) ?
                          <Card key={i} style={{backgroundColor: item.backgroundColor}}>
                            <CardBody>
                              <CardTitle className="mb-4 font-size-18"><p dangerouslySetInnerHTML={{ __html: (item.name) }}></p></CardTitle>
                              <Row style={{marginTop: '-20px'}}>
                                <Col lg="6">
                                  <p className="font-size-11 fst-italic" dangerouslySetInnerHTML={{ __html: ((item.price ? item.price + '<br>' + item.time : item.time)) }}></p>
                                </Col>
                                <Col lg="6" className="d-flex justify-content-end align-self-center">
                                  <button
                                    className="btn btn-primary btn-block"
                                    type="submit"
                                    onClick={() => {onTestChange(i)}}
                                  >
                                    <i className="fas fa-hand-pointer text-white"></i> {isGift ? t('accept') : t('choose')}
                                  </button>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        : ''
                      )}
                    </TabPane>
                    <TabPane tabId={2}>
                      <Card>
                        <CardBody>
                          <Row
                            style={{
                              flex: 1,
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              margin: '0',
                              width: '100%',
                              textAlign: 'center'
                            }}
                          >
                            <DayPickerSingleDateController
                              date={date}
                              onDateChange={(date) => onDateChange(date)}
                              focused={focused}
                              onFocusChange={({ focused }) => setFocused(!focused)}
                              hideKeyboardShortcutsPanel={true}
                              numberOfMonths={isDekstop ? 2 : isMobile ? 1 : isLaptop ? 2 : 1}
                              isOutsideRange={isOutsideRange}
                              isDayBlocked={day => moment.weekdays(day.isoWeekday()) === 'Sunday' || moment("2022-01-01").isSame(day)}
                              orientation={'horizontal'}
                              //daySize={isTabletOrMobile ? 37 : 120}
                              daySize={isDekstop ? 127 : isMobile ? 35 : isLaptop ? 95 : 35}
                              onNextMonthClick={(day) => setShowInput(!showInput)}
                              onPrevMonthClick={(day) => setShowInput(!showInput)}
                              showInput={showInput}
                            />
                          </Row>
                        </CardBody>
                      </Card>
                      <Card>
                        <CardBody>
                          <h4 className="card-title mb-4">{t('time_of_arrival')}</h4>
                          <div className="button-items">
                            {availableSlots.map((item, i) =>
                                <Button
                                  color="primary"
                                  className="btn btn-primary btn-lg text-nowrap"
                                  key={i}
                                  onClick={() => {
                                    onTimeChange(availableSlots[i])
                                  }}
                                >
                                  {availableSlots[i]}
                                </Button>
                              )}
                          </div>
                        </CardBody>
                      </Card>
                    </TabPane>
                    <TabPane tabId={4}>
                      <Card>
                        <CardBody>
                          <CardTitle className="mb-4">{t('confirm_appointment_2')}</CardTitle>
                          <div className="text-center">
                            <div className="avatar-sm mx-auto mb-4">
                              <span className="avatar-title rounded-circle bg-primary bg-soft font-size-24"
                              >
                                <i className="fas fa-calendar-check text-primary fa-2x"></i>
                              </span>
                            </div>
                            <p className="font-16 text-muted mb-2"></p>
                            <h5>
                            {t('confirm_appointment_2')}
                            </h5>
                            <p className="text-muted">
                              {t('confirm_appointment_click')}
                            </p>
                            <Link to="#" className="text-primary font-16">
                              {t('see_more')} <i className="mdi mdi-chevron-right"></i>
                            </Link>
                          </div>
                          <Row className="mt-4">

                          </Row>
                        </CardBody>
                      </Card>
                    </TabPane>
                    <TabPane tabId={3}>
                      <Card>
                        <CardBody>
                          <Row>
                            <Col lg="6" className={isDekstop ? "border-end" : "border-bottom"}>
                              <AvForm className="form-horizontal" onSubmit={handleSubmit} ref={formEl}>
                                <div className="mb-3">
                                  <AvField
                                    name="firstname"
                                    label={t('firstname')}
                                    className="form-control"
                                    placeholder={t('enter_firstname')}
                                    type="text"
                                    required
                                    errorMessage={t('enter_firstname')}
                                  />
                                </div>
                                <div className="mb-3">
                                  <AvField
                                    name="lastname"
                                    label={t('lastname')}
                                    className="form-control"
                                    placeholder={t('enter_lastname')}
                                    type="text"
                                    required
                                    errorMessage={t('enter_lastname')}
                                  />
                                </div>
                                <div className="mb-3">
                                  <AvField
                                    name="email"
                                    label={t('email')}
                                    className="form-control"
                                    placeholder={t('enter_email')}
                                    type="email"
                                    errorMessage={t('enter_email')}
                                  />
                                </div>
                                <div className="mb-3">
                                  <label for="exampleFormControlInput1" class="form-label">{t('phone')}*</label>
                                  <PhoneInput
                                    country={'ba'}
                                    preferredCountries={['ba', 'rs', 'hr', 'sl']}
                                    value={phone}
                                    enableSearch={true}
                                    onChange={phone => setPhone(phone)}
                                    defaultErrorMessage={t('enter_phone')}
                                    inputClass={"form-select"}
                                    inputStyle={{width: '100%'}}
                                    inputProps={{
                                      name: 'phone',
                                      required: true,
                                      autoFocus: true
                                    }}
                                  />
                                  {phoneError && <div class="invalid-feedback" style={{display: 'block'}}>{t('enter_phone')}</div>}
                                </div>
                                <div className="mb-3">
                                  <AvInput
                                    label={t('note')}
                                    type="textarea"
                                    name="notes"
                                    placeholder={t('note')}
                                  />
                                </div>
                                {/*<div className="mb-3">
                                  <div class="form-check">
                                    <input
                                      type="checkbox"
                                      id="dolazak"
                                      className="form-check-input"
                                      defaultChecked={dolazak}
                                      onChange={() => setDolazak(!dolazak)}
                                    />
                                    <label className="form-check-label" for="dolazak">
                                      {t('patronatsdienst_text')}
                                    </label>
                                  </div>
                                </div>
                                  */}
                                {dolazak ?
                                  <div>
                                    <div className="mb-3">
                                      <AvField
                                        name="street"
                                        label={t('street_and_number')}
                                        className="form-control"
                                        placeholder={t('enter_street_and_number')}
                                        type="text"
                                        required
                                        errorMessage={t('please_enter_street_and_number')}
                                      />
                                    </div>
                                    <div className="mb-3">
                                      <AvField
                                        name="city"
                                        label={t('city')}
                                        className="form-control"
                                        placeholder={t('enter_city')}
                                        type="text"
                                        value="Sarajevo"
                                        disabled
                                        errorMessage={t('please_enter_city')}
                                      />
                                    </div>
                                  </div>
                                : ''}
                              </AvForm>
                            </Col>
                            <Col lg="6">
                              <Media>
                                <Media body>
                                  <h3 className="mb-0 mt-1">{test ? <p dangerouslySetInnerHTML={{ __html: (test.name) }}></p> : ''}</h3>
                                  <p>
                                    <Row>
                                      <Col lg="12">
                                        <Row>
                                          <Col>
                                            <h6>{t('date')}:</h6>
                                          </Col>
                                          <Col>
                                            <h6>{moment(date).format('DD.MM.YYYY')}</h6>
                                          </Col>
                                        </Row>  
                                      </Col>
                                      <Col lg="12">
                                        <Row>
                                          <Col>
                                            <h6>{t('time')}:</h6>
                                          </Col>
                                          <Col>
                                            <h6>{time}</h6>
                                          </Col>
                                        </Row>  
                                      </Col>
                                      <Col lg="12">
                                        <Row>
                                          <Col>
                                            <h6>{t('test')}:</h6>
                                          </Col>
                                          <Col>
                                            <h6>{test ? <p dangerouslySetInnerHTML={{ __html: (test.name) }}></p> : ''}</h6>
                                          </Col>
                                        </Row>  
                                      </Col>
                                      <Col lg="12">
                                        <Row>
                                          <Col lg="6" sm="3">
                                            <h6>{t('location')}:</h6>
                                          </Col>
                                          <Col lg="6" sm="3">
                                            <a
                                              href="https://goo.gl/maps/Hy7p4psKnQ2YYE5W9"
                                              style={{
                                                fontSize: 16
                                              }}
                                            >
                                              Tešanjska 1, Sarajevo 71000
                                            </a>
                                          </Col>
                                        </Row>  
                                      </Col>
                                      <Col lg="12">
                                        <div className="mt-3 d-grid">
                                          <button
                                            className="btn btn-primary btn-block "
                                            type="submit"
                                            onClick={() => {
                                              setconfirm_alert(true)
                                            }}
                                          >
                                            {t('confirm_appointment')}
                                          </button>
                                        </div>
                                      </Col>
                                    </Row>
                                  </p>
                                </Media>
                              </Media>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </TabPane>
                  </TabContent>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {confirm_alert ? (
        <SweetAlert
          title={t('information_correct')}
          warning
          showCancel
          confirmButtonText={t('yes')}
          cancelButtonText={t('cancel')}
          cancelBtnText={t('cancel')}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            formEl.current && formEl.current.submit()
            setconfirm_alert(false)
          }}
          onCancel={() => setconfirm_alert(false)}
        >
          <div className="mt-3 d-grid">
            <p>{t('date')}: {moment(date).format('DD.MM.YYYY')}</p>
            <p>{t('time')}: {time}</p>
          </div>
        </SweetAlert>
      ) : null}

      {time_alert ? (
        <SweetAlert
          warning
          showCancel
          confirmButtonText={t('ok')}
          cancelButtonText={t('cancel')}
          cancelBtnText={t('cancel')}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            toggleTab(2)
            setTime_alert(false)
          }}
          onCancel={() => setTime_alert(false)}
        >
          <div className="mt-3 d-grid">
            <p>{t('patronatsdienst')}</p>
          </div>
        </SweetAlert>
      ): null}

      {gift_invalid ? (
        <SweetAlert
          warning
          showCancel
          confirmButtonText={t('ok')}
          cancelButtonText={t('cancel')}
          cancelBtnText={t('cancel')}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            setGiftInvalid_alert(false)
          }}
          onCancel={() => setGiftInvalid_alert(false)}
        >
          <div className="mt-3 d-grid">
            <p>Link poklona je iskorišten ili je istekao!</p>
          </div>
        </SweetAlert>
      ): null}
    </div>
  )
}

export default CalendarRegister
