import React, {useState} from "react"
import { Link, useHistory } from "react-router-dom"
import { Col, Card, CardBody, CardTitle, InputGroup, Container, Form, FormGroup, Label, Row, Input, FormFeedback } from "reactstrap"
import { AvForm, AvField, AvGroup, AvFeedback } from "availity-reactstrap-validation"
import { useDispatch, useSelector } from "react-redux";
import { changePassword, userSelector, clearState } from "../../slices/UserSlice";


const Profile = () => {
  const dispatch = useDispatch();

  const { errors, loading, data, roles } = useSelector(userSelector);  
  const handeSubmit = (event, values) => {
    console.log(errors);
    event.preventDefault();
    dispatch(changePassword(values));
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Izmjena šifre</CardTitle>
                  <AvForm
                    onValidSubmit={handeSubmit}
                  >
                    <div className="mb-3">
                      <FormGroup>
                        <AvField
                          name="current_password"
                          label="Trenutna šifra"
                          className="form-control"
                          placeholder="Unesite trenutnu šifru"
                          type="password"
                          invalid={errors?.current_password}
                          required
                          errorMessage="Molimo unesite ime pacijenta"
                        />
                        {errors?.current_password && <FormFeedback className={"d-block ".concat(errors?.current_password ? "visible" : "invisible")}>{errors?.current_password}</FormFeedback>}
                      </FormGroup>
                    </div>
                    <div className="mb-3">
                      <FormGroup>
                        <AvField
                          name="password"
                          label="Nova šifra"
                          className="form-control"
                          placeholder="Unesite novu šifru"
                          type="password"
                          invalid={errors?.password}
                          required
                        />
                        {errors?.password && <FormFeedback className={"d-block ".concat(errors?.password ? "visible" : "invisible")}>{errors?.password}</FormFeedback>}
                      </FormGroup>
                    </div>
                    <div className="mb-3">
                      <FormGroup>
                        <AvField
                          name="password_confirmation"
                          label="Potvrda nove šifra"
                          className="form-control"
                          placeholder="Unesite potvrdu nove šifru"
                          type="password"
                          value=""
                          invalid={errors?.password_confirmation}
                          required
                        />
                        {errors?.password_confirmation && <FormFeedback className={"d-block ".concat(errors?.password_confirmation ? "visible" : "invisible")}>{errors?.password_confirmation}</FormFeedback>}
                      </FormGroup>
                    </div>
                    <div>
                      <button 
                        type="submit" 
                        className="btn btn-primary w-md"
                        disabled={loading}
                      >
                        {loading ? "Učitavanje...": "Submit"}
                      </button>
                    </div>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Profile;