
import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { userSelector } from "../slices/UserSlice";

export const UserRoute = ({
  component,
  path,
  admin,
  ...rest
}) => {
  const { accessToken, roles } = useSelector(userSelector);
  return (accessToken !== null) ? (
    <Route exact path={path} component={component} {...rest} />
  ) : (
      <Redirect to={"/auth/login"} />
    );
};