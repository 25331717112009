import React from "react"
import { ButtonGroup, NavItem, Table, NavLink, Button, Media, Progress, TabContent, TabPane, Row, Col, Card, CardBody, FormGroup, Form, FormFeedback, CardTitle, Input, Dropdown, DropdownMenu, DropdownItem, DropdownToggle, ButtonDropdown, Label, Container, Modal, ModalHeader, ModalBody } from "reactstrap"
import { Link, useHistory, useLocation } from "react-router-dom"
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';

const AppointmentVerify = () => {
  const { t, i18n } = useTranslation();
  const [type, setType] = React.useState('');
  const { search } = useLocation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  }

  React.useEffect(() => {
    const values = queryString.parse(search);
    if(values.lang) changeLanguage(values.lang);

    const params = new URLSearchParams(window.location.search)
    let success = params.get('success')
    if(success == "true"){
      setType('success')
    }

    let failed = params.get('failed')
    if(failed == "true"){
      setType('failed')
    }
  }, [type])

  return (
    <React.Fragment>
      <div>
        <Container fluid={true}>
          <Row>
            <div className="text-center" style={{marginTop: '25px'}}>
              <img src="https://i.imgur.com/nmJUHy0.png" style={{width: 260, height: 70}} />
            </div>
            <Col xl={12} style={{marginTop: '25px'}}>
              <Card>
                {type == "success" ? 
                  <CardBody>
                    <CardTitle className="mb-4">{t('confirm_appointment')}</CardTitle>
                    <div className="text-center">
                      <div className="avatar-sm mx-auto mb-4">
                        <span className="avatar-title rounded-circle bg-primary bg-soft font-size-24"
                        >
                          <i className="fas fa-calendar-check text-primary fa-2x"></i>
                        </span>
                      </div>
                      <p className="font-16 text-muted mb-2"></p>
                      <h5>
                      {t('success')}
                      </h5>
                      <p className="text-muted">
                        {t('confirmed_appointment')}.<br />{t('your')} Labos
                      </p>
                      <a className="btn btn-success" href="https://labos.ba">{t('return_labos')}</a>
                    </div>
                  </CardBody>
                : 
                  <CardBody>
                    <CardTitle className="mb-4">{t('confirm_appointment')}</CardTitle>
                    <div className="text-center">
                      <div className="avatar-sm mx-auto mb-4">
                        <span className="avatar-title rounded-circle bg-primary bg-soft font-size-24"
                        >
                          <i className="fas fa-calendar-times text-primary fa-2x"></i>
                        </span>
                      </div>
                      <p className="font-16 text-muted mb-2"></p>
                      <h5>
                      {t('error')}!
                      </h5>
                      <p className="text-muted">
                        {t('link_expired')}
                      </p>
                      <a className="btn btn-success" href="https://labos.ba">{t('return_labos')}</a>
                    </div>
                  </CardBody>
                }
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default AppointmentVerify
