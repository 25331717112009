import React,{ Suspense } from "react"
import ReactDOM from "react-dom"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import { BrowserRouter } from "react-router-dom"
//import "./i18n"
import { Provider } from "react-redux"
import { store } from './app/store';
import ReduxToastr from 'react-redux-toastr';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import './i18n';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <>
        <ReduxToastr
          timeOut={4000}
          newestOnTop={false}
          preventDuplicates
          position="bottom-right"
          getState={(state) => state.toastr} // This is the default
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          progressBar
          closeOnToastrClick
        />
		<Suspense fallback={"loading"}>
          <App />
        </Suspense>
      </>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister()
