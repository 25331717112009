import React, {useState, useRef, useEffect } from "react"
import { Link, useHistory } from "react-router-dom"
import { Row, Button, Col, Card, CardBody, FormGroup, Form, ModalFooter, FormFeedback, CardTitle, Input, Dropdown, DropdownMenu, DropdownItem, DropdownToggle, ButtonDropdown, Label, Container, Modal, ModalHeader, ModalBody } from "reactstrap"
import { useDispatch, useSelector } from "react-redux";
import { getAllAppointments2, adminSelector, updateGlobalWt, markAppointment, cancelAppointment, updateSeparateWt, getAllWorkingTimes, clearState, clearErrors } from "../../slices/AdminSlice";
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation"
import PhoneInput from 'react-phone-input-2'
import { DayPickerSingleDateController, isInclusivelyBeforeDay, isInclusivelyAfterDay } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import { getAllAppointments, registerCalendar, userSelector } from "../../slices/UserSlice";

// import images
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css';
moment.locale('bs', {
  week: {
      dow: new Date().getDay(),
      doy: new Date().getDay(),
  },
});


const AppointmentCalendar = () => {
  const dispatch = useDispatch();
  const localizer = momentLocalizer(moment)
  const dayFormat = (date, culture, localizer) => localizer.format(date, 'D MMMM YYYY', culture);
  const { loading, error, errors, appointments, workingTimes } = useSelector(adminSelector);

  useEffect(() => {
    dispatch(getAllAppointments2());
    dispatch(getAllAppointments());
    dispatch(getAllWorkingTimes());
  }, [dispatch]);


  const [globalWtModal, setGlobalWtModal] = useState(false);
  const [separateWtModal, setSeparateWtModal] = useState(false);
  const [infoModal, setInfoModal] = useState(false);
  const [infoModalData, setInfoModalData] = useState([]);
  const [appointmentModal, setAppointmentModal] = useState(false);

  const toggleGlobalWt = () => {
    dispatch(clearErrors());
    setGlobalWtModal(!globalWtModal)
  }

  const toogleSeparateWt = () => {
    dispatch(clearErrors());
    setSeparateWtModal(!separateWtModal)
  }

  const handleGlobalWorkingTimeSubmit = (event, errors, values) => {
    event.preventDefault();
    dispatch(updateGlobalWt(values, toggleGlobalWt));
  }

  const handleSeparateWorkingTimeSubmit = (event, errors, values) => {
    event.preventDefault();
    dispatch(updateSeparateWt(values, toggleGlobalWt));
  }

  const toogleInfo = (data) => {
    setInfoModal(!infoModal)
    setInfoModalData(data)
  }

  const toogleAppointment = () => {
    setAppointmentModal(!appointmentModal)
    setPhone(null)
    setTest(null)
    setTime(null)
  }
  
  const handleCancelAppointment = () => {
    dispatch(cancelAppointment(infoModalData.uuid, toogleInfo));
  }

  const handleMarkAppointment = () => {
    dispatch(markAppointment(infoModalData.uuid, toogleInfo));
  }


  // APPOINTMENT FORM
  const types = [
    {
      id: "6ac5d4a4-5030-4db2-aedd-c526739b5fb4",
      name: "SARS CoV-2 <b>PCR</b>"
    },
    {
      id: "db94dfdd-4716-45db-9586-90e6b83d21b7",
      name: "SARS CoV-2 <b>Antigen</b>"
    },
    {
      id: "dae17e88-4022-11ec-9356-0242ac130003",
      name: "Ostalo"
    }
  ];
  
  function onTestChange(index){
    setTest(types[index])
  }

  const { slots } = useSelector(userSelector);
  const [focused, setFocused] = React.useState(true);
  const [showInput, setShowInput] = React.useState(false);

  const minDate = moment().subtract(1, 'days')
  const maxDate = moment().add(3, 'months').endOf('month')
  const isOutsideRange = day => isInclusivelyBeforeDay(day, minDate) || isInclusivelyAfterDay(day, maxDate)
  const [availableSlots, setAvailableSlots] = React.useState([]);

  function onDateChange(date){
    setDate(date)
    setAvailableSlots(slots[moment(date).format('DD.MM.YYYY')])
  }

  function onTimeChange(time){
    setTime(time)
  }

  const [date, setDate] = React.useState(moment());
  const [time, setTime] = React.useState();
  const [test, setTest] = React.useState();
  const [phone, setPhone] = React.useState();
  const [phoneError, setPhoneError] = React.useState(false);
  const [dolazak, setDolazak] = React.useState(false);

  const formEl = useRef(null)
  const handleSubmit = (event, errors, values) => {
    values['admin'] = true
    values['test'] = test.id
    values['time'] = time
    values['phone'] = phone
    values['date'] = moment(date).format('YYYY-MM-DD')
    if(!phone){
      setPhoneError(true)
    }else{
      if(dolazak){
        values['address'] = {
          'street': values.street
        }
      }
      values['arrival_at_home'] = dolazak
      setPhoneError(false)
      dispatch(registerCalendar(values, toogleAppointment));
      dispatch(getAllAppointments2());
    }
  }


  
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/*<Breadcrumbs title="Tables" breadcrumbItem="Data Tables" />*/}
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <CardTitle className="h4">Pregled rezervacija</CardTitle>
                    <div className="button-items py-2">
                      <button 
                        onClick={toggleGlobalWt}
                        className="btn btn-success">Izmjeni radno vrijeme</button>
                      <button 
                        onClick={toogleSeparateWt}
                        className="btn btn-success">Radno vrijeme za pojedinacan dan</button>
                        <button 
                        onClick={toogleAppointment}
                        className="btn btn-success">Zakaži rezervaciju</button>
                    </div>
                    <Calendar
                      formats={{
                        dayFormat,
                        timeGutterFormat: (date, culture, localizer) => 
                            localizer.format(date, 'H:mm', culture),
                      }}
                      showMultiDayTimes
                      date={new Date(Date.now())}
                      selectable="ignoreEvents"
                      localizer={localizer}
                      eventPropGetter={(event, start, end, isSelected) => {
                        if(event.status == 1){
                          return { className: "cancel", style: { backgroundColor: '#f46a6a', borderColor: '#f46a6a' } }
                        }

                        if(event.status == 2){
                          return { className: "success", style: { backgroundColor: '#78b845', borderColor: '#78b845' } }
                        }
                      }}
                      events={appointments ? appointments: null}
                      step={10}
                      timeslots={1}
                      startAccessor="start"
                      endAccessor="end"
                      style={{ height: 500 }}
                      min={new Date(new Date().setHours(7, 0, 0, 0))}
                      max={new Date(new Date().setHours(19, 0, 0, 0))}
                      date={new Date()}
                      onSelectEvent={event => 
                        toogleInfo(event)

                        /*alert(event.title)*/
                      }
                      //onSelectSlot={handleSelect}
                      dayLayoutAlgorithm="no-overlap"
                      defaultView='week'
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {/* APPOINTMENT MODAL */}
            <Modal
              isOpen={appointmentModal}
              toggle={toogleAppointment}
            >
              <ModalHeader toggle={toogleAppointment} tag="h4">
                Zakazivanje termina
              </ModalHeader>
              <ModalBody>
                <AvForm className="form-horizontal" onSubmit={handleSubmit} ref={formEl}>
                  <div className="mb-3">
                    <AvField
                      name="firstname"
                      label="Ime*"
                      className="form-control"
                      placeholder="Unesite ime"
                      type="text"
                      required
                      errorMessage="Molimo unesite ime"
                    />
                  </div>
                  <div className="mb-3">
                    <AvField
                      name="lastname"
                      label="Prezime*"
                      className="form-control"
                      placeholder="Unesite prezime"
                      type="text"
                      required
                      errorMessage="Molimo unesite prezime"
                    />
                  </div>
                  <div className="mb-3">
                    <AvField
                      name="email"
                      label="Email"
                      className="form-control"
                      placeholder="Unesite email"
                      type="email"
                      errorMessage="Molimo unesite email"
                    />
                  </div>
                  <div className="mb-3">
                    <label for="exampleFormControlInput1" class="form-label">Unesite broj telefona*</label>
                    <PhoneInput
                      country={'ba'}
                      preferredCountries={['ba', 'rs', 'hr', 'sl']}
                      value={phone}
                      enableSearch={true}
                      onChange={phone => setPhone(phone)}
                      defaultErrorMessage={"Molimo unesite broj telefona"}
                      inputClass={"form-select"}
                      inputStyle={{width: '100%'}}
                      inputProps={{
                        name: 'phone',
                        required: true,
                        autoFocus: true
                      }}
                    />
                    {phoneError && <div class="invalid-feedback" style={{display: 'block'}}>Molimo unesite broj telefona</div>}
                  </div>
                  <div className="mb-3">
                    <AvInput
                      type="textarea"
                      name="notes"
                      placeholder="Napomena"
                    />
                  </div>
                  <div className="mb-3">
                    <div class="form-check">
                      <input
                        type="checkbox"
                        id="dolazak"
                        className="form-check-input"
                        defaultChecked={dolazak}
                        onChange={() => setDolazak(!dolazak)}
                      />
                      <label className="form-check-label" for="dolazak">
                        Dolazak na adresu?
                      </label>
                    </div>
                  </div>
                  {dolazak ?
                    <div>
                      <div className="mb-3">
                        <AvField
                          name="street"
                          label="Ulica i br."
                          className="form-control"
                          placeholder="Unesite ulicu i broj"
                          type="text"
                          errorMessage="Molimo unesite ulicu i broj"
                        />
                      </div>
                      <div className="mb-3">
                        <AvField
                          name="city"
                          label="Grad"
                          className="form-control"
                          placeholder="Unesite grad"
                          type="text"
                          value="Sarajevo"
                          disabled
                          errorMessage="Molimo unesite grad"
                        />
                      </div>
                    </div>
                  : ''}
                </AvForm>
                <Card>
                  <CardBody>
                    <h4 className="card-title mb-4 mt-2">Test</h4>
                    <div className="button-items">
                      {types.map((item, i) =>
                          <Button
                            color="primary"
                            className={"btn btn-lg text-nowrap " + (item?.id==test?.id ? "btn-light" : "btn-primary")}
                            key={i}
                            dangerouslySetInnerHTML={{ __html: (item.name) }}
                            onClick={() => {
                              onTestChange(i)
                            }}
                          >
                          </Button>
                        )}
                    </div>
                    <div className="mt-2">
                      <DayPickerSingleDateController
                        date={date}
                        onDateChange={(date) => onDateChange(date)}
                        focused={focused}
                        onFocusChange={({ focused }) => setFocused(!focused)}
                        hideKeyboardShortcutsPanel={true}
                        numberOfMonths={1}
                        isOutsideRange={isOutsideRange}
                        isDayBlocked={day => moment.weekdays(day.isoWeekday()) === 'Sunday'}
                        orientation={'horizontal'}
                        daySize={52}
                        onNextMonthClick={(day) => setShowInput(!showInput)}
                        onPrevMonthClick={(day) => setShowInput(!showInput)}
                        showInput={showInput}
                      />
                    </div>
                    <h4 className="card-title mb-4 mt-5">Vrijeme dolaska</h4>
                    <div className="button-items">
                      {availableSlots.map((item, i) =>
                          <Button
                            color="primary"
                            className={"btn btn-lg text-nowrap " + (availableSlots[i]==time ? "btn-light" : "btn-primary")}
                            key={i}
                            onClick={() => {
                              onTimeChange(availableSlots[i])
                            }}
                          >
                            {availableSlots[i]}
                          </Button>
                        )}
                    </div>
                  </CardBody>
                </Card>
              </ModalBody>
              <ModalFooter>
                <button type="button" className="btn btn-lg btn-success w-100" onClick={() => {
                  formEl.current && formEl.current.submit()
                }
                }>Sačuvaj</button>
              </ModalFooter>
            </Modal>
            {/* INFO MODAL */}
            <Modal
              isOpen={infoModal}
              toggle={toogleInfo}
            >
              <ModalHeader toggle={toogleInfo} tag="h4">
                Termin
              </ModalHeader>
              <ModalBody>
                <Card>
                  <div>
                    <Row>
                      <Col lg="9" sm="8">
                        <div className="p-4">
                          <div className="text-muted">
                            <p className="mb-1">Termin: {infoModalData?.uuid}</p>
                            <p className="mb-1">Datum kreiranja rezervacije: {infoModalData?.created_at}</p>
                            <p className="mb-1">Status: {infoModalData?.status == 1 ? 'Rezervacija je otkazana!' : ''} {infoModalData?.status == 2 ? 'Rezervacija je obrađena!' : ''}</p>
                          </div>
                          <div className="text-muted">
                            <p className="mb-1">
                              <i className="mdi mdi-circle-medium align-middle text-primary me-1"/>{" "}
                              Ime: <strong>{infoModalData?.info?.firstName}</strong>
                            </p>
                            <p className="mb-1">
                              <i className="mdi mdi-circle-medium align-middle text-primary me-1"/>{" "}
                              Prezime: <strong>{infoModalData?.info?.lastName}</strong>
                            </p>
                            <p className="mb-0">
                              <i className="mdi mdi-circle-medium align-middle text-primary me-1"/>{" "}
                              Telefon: <strong>{infoModalData?.info?.phone ? infoModalData?.info?.phone : '/'}</strong>
                            </p>
                            <p className="mb-0">
                              <i className="mdi mdi-circle-medium align-middle text-primary me-1"/>{" "}
                              Email: <strong>{infoModalData?.info?.email ? infoModalData?.info?.email : '/'}</strong>
                            </p>
                            <p className="mb-0">
                              <i className="mdi mdi-circle-medium align-middle text-primary me-1"/>{" "}
                              Datum: <strong>{moment(infoModalData?.info?.date).format('DD.MM.YYYY')}</strong>
                            </p>
                            <p className="mb-0">
                              <i className="mdi mdi-circle-medium align-middle text-primary me-1"/>{" "}
                              Vrijeme: <strong>{infoModalData?.info?.time}</strong>
                            </p>
                            <p className="mb-0">
                              <i className="mdi mdi-circle-medium align-middle text-primary me-1"/>{" "}
                              Test: <strong>{infoModalData?.info?.test?.name}</strong>
                            </p>
                            <p className="mb-0">
                              <i className="mdi mdi-circle-medium align-middle text-primary me-1"/>{" "}
                              Dolazak na adresu: <strong>{infoModalData?.info?.arrival_at_home ? 'DA' : 'NE'}</strong>
                            </p>
                            {infoModalData?.info?.arrival_at_home ? 
                              <p className="mb-0">
                                <i className="mdi mdi-circle-medium align-middle text-primary me-1"/>{" "}
                                Adresa: <strong>{infoModalData?.info?.address?.street}, {infoModalData?.info?.address?.city}</strong>
                              </p>
                            : '' }
                            <p className="mb-0">
                              <i className="mdi mdi-circle-medium align-middle text-primary me-1"/>{" "}
                              Napomena: <strong>{infoModalData?.info?.notes ? infoModalData?.info?.notes : '/'}</strong>
                            </p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Card>
              </ModalBody>
              <ModalFooter>
                {infoModalData?.status == 0 ? 
                  <button type="button" className="btn btn-danger" onClick={() => {
                    handleCancelAppointment()
                  }}>Otkaži rezervaciju</button>
                : ''
                }

                {infoModalData?.status == 0 ? 
                  <button type="button" className="btn btn-success" onClick={() => {
                    handleMarkAppointment()
                  }
                  }>Označi kao obrađeno</button>
                : ''
                }
              </ModalFooter>
            </Modal>
             {/* .///INFO MODAL */}
            <Modal
              isOpen={globalWtModal}
              toggle={toggleGlobalWt}
            >
              <ModalHeader toggle={toggleGlobalWt} tag="h4">
                Globalno radno vrijeme
              </ModalHeader>
              <ModalBody>
                <AvForm
                  onSubmit={
                    handleGlobalWorkingTimeSubmit
                  }
                >
                <Row form>
                  <Col xs={12}>
                    <div className="mb-3">
                      <FormGroup>
                        <AvField
                          name="start_working_at"
                          label="Početak radnog vremena"
                          className="form-control"
                          placeholder="Početak radnog vrijeme"
                          type="time"
                          value={workingTimes?.global?.start_working_at || ""}
                          invalid={errors?.start_working_at}
                          required
                        />
                        {errors?.start_working_at && <FormFeedback className={"d-block ".concat(errors?.start_working_at ? "visible" : "invisible")}>{errors?.start_working_at}</FormFeedback>}
                      </FormGroup>
                    </div>
                    <div className="mb-3">
                      <FormGroup>
                        <AvField
                          name="end_working_at"
                          label="Završetak radnog vremena"
                          className="form-control"
                          placeholder="Završetak radnog vrijeme"
                          type="time"
                          value={workingTimes?.global?.end_working_at || ""}
                          invalid={errors?.end_working_at}
                          required
                        />
                        {errors?.end_working_at && <FormFeedback className={"d-block ".concat(errors?.end_working_at ? "visible" : "invisible")}>{errors?.end_working_at}</FormFeedback>}
                      </FormGroup>
                    </div>
                    <div className="mb-3">
                      <FormGroup>
                        <AvField
                          name="slot_interval"
                          label="Razmak između termina"
                          className="form-control"
                          placeholder="Razmak između vrijeme"
                          type="number"
                          value={workingTimes?.global?.slot_interval || ""}
                          invalid={errors?.slot_interval}
                          required
                        />
                        {errors?.slot_interval && <FormFeedback className={"d-block ".concat(errors?.slot_interval ? "visible" : "invisible")}>{errors?.slot_interval}</FormFeedback>}
                      </FormGroup>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                      >Spremi</button>
                    </div>
                  </Col>
                </Row>
                </AvForm>
              </ModalBody>
            </Modal>

            { /* SEPARATE */}
            <Modal
              isOpen={separateWtModal}
              toggle={toogleSeparateWt}
            >
              <ModalHeader toggle={toogleSeparateWt} tag="h4">
                Pojedinačno radno vrijeme
              </ModalHeader>
              <ModalBody>
                <AvForm
                  onSubmit={
                    handleSeparateWorkingTimeSubmit
                  }
                >
                <Row form>
                  <Col xs={12}>
                  <div className="mb-3">
                      <FormGroup>
                        <AvField
                          name="date"
                          label="Radni dan"
                          className="form-control"
                          placeholder="Radni dan"
                          type="date"
                          value={workingTimes?.global?.date || ""}
                          invalid={errors?.date}
                          required
                        />
                        {errors?.date && <FormFeedback className={"d-block ".concat(errors?.date ? "visible" : "invisible")}>{errors?.date}</FormFeedback>}
                      </FormGroup>
                    </div>
                    <div className="mb-3">
                      <FormGroup>
                        <AvField
                          name="start_working_at"
                          label="Početak radnog vremena"
                          className="form-control"
                          placeholder="Početak radnog vrijeme"
                          type="time"
                          value={workingTimes?.global?.start_working_at || ""}
                          invalid={errors?.start_working_at}
                          required
                        />
                        {errors?.start_working_at && <FormFeedback className={"d-block ".concat(errors?.start_working_at ? "visible" : "invisible")}>{errors?.start_working_at}</FormFeedback>}
                      </FormGroup>
                    </div>
                    <div className="mb-3">
                      <FormGroup>
                        <AvField
                          name="end_working_at"
                          label="Završetak radnog vremena"
                          className="form-control"
                          placeholder="Završetak radnog vrijeme"
                          type="time"
                          value={workingTimes?.global?.end_working_at || ""}
                          invalid={errors?.end_working_at}
                          required
                        />
                        {errors?.end_working_at && <FormFeedback className={"d-block ".concat(errors?.end_working_at ? "visible" : "invisible")}>{errors?.end_working_at}</FormFeedback>}
                      </FormGroup>
                    </div>
                    <div className="mb-3">
                      <FormGroup>
                        <AvField
                          name="slot_interval"
                          label="Razmak između termina"
                          className="form-control"
                          placeholder="Razmak između vrijeme"
                          type="number"
                          value={workingTimes?.global?.slot_interval || ""}
                          invalid={errors?.slot_interval}
                          required
                        />
                        {errors?.slot_interval && <FormFeedback className={"d-block ".concat(errors?.slot_interval ? "visible" : "invisible")}>{errors?.slot_interval}</FormFeedback>}
                      </FormGroup>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                      >Spremi</button>
                    </div>
                  </Col>
                </Row>
                </AvForm>
              </ModalBody>
            </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AppointmentCalendar
