import React, {useState} from "react"
import { Link, useHistory } from "react-router-dom"
import { Col, Container, Form, FormGroup, Label, Row, Input } from "reactstrap"

import { AvForm, AvField } from "availity-reactstrap-validation"
import { useDispatch, useSelector } from "react-redux";
import { login, userSelector, clearState } from "../../slices/UserSlice";
// import images
import CarouselPage from "./CarouselPage"

const Login = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { loading, error, errors } = useSelector(userSelector);

  const handleSignIn = (event, errors, values) => {
    event.preventDefault();
    console.log("KLIK");
    //poziv
    dispatch(login(values, history));
  };




  return (
    <React.Fragment>
       <div>
          <Container fluid className="p-0">
            <Row className="g-0">
              <CarouselPage />
              <Col xl={3}>
                <div className="auth-full-page-content p-md-5 p-4">
                  <div className="w-100">
                    <div className="d-flex flex-column h-100">
                      <div className="my-auto">
                        <div>
                          <h5 className="text-primary">Dobrodošli!</h5>
                          <p className="text-muted">
                            Molimo Vas da se prijavite kako biste nastavili dalje.
                          </p>
                        </div>

                        <div className="mt-4">
                          <AvForm className="form-horizontal" onSubmit={handleSignIn}>
                            <div className="mb-3">
                              <AvField
                                name="username"
                                label="Korisničko ime"
                                className="form-control"
                                placeholder="Unesite korisničko ime"
                                type="text"
                                required
                                errorMessage="Molimo unesite korisničko ime"
                              />
                            </div>
                            <div className="mb-3">
                            <div className="float-end">
                              <Link
                                to="/auth/forgotpassword"
                                className="text-muted"
                              >
                                Zaboravili ste šifru?
                              </Link>
                            </div>
                              <AvField
                                name="password"
                                label="Šifra"
                                className="form-control"
                                placeholder="Unesite šifru"
                                type="password"
                                required
                                errorMessage="Molimo unesite šifru"
                              />
                            </div>
                            <div className="mt-3 d-grid">
                              <button
                                className="btn btn-primary btn-block "
                                type="submit"
                              >
                                {loading ? "Ucitavanje": "Prijavite se"}
                              </button>
                            </div>
                          </AvForm>
                          <div className="mt-5 text-center">
                            <p>
                              Još nemate profil? Registrujte se besplatno i ostvarite mnoge pogodnosti koje Vam pruža LABOS eKarton.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="mt-4 mt-md-5 text-center">
                        <p className="mb-0">
                          © {new Date().getFullYear()} Labos.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
    </React.Fragment>
  )
}

export default Login
