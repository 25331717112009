import React from 'react';
import AppRouter from './router';
import { BrowserRouter } from "react-router-dom";
import {toastr} from 'react-redux-toastr';
import axios from "axios";
import "./assets/scss/theme.scss"
import Sidebar from './components/Sidebar'
/*
axios.interceptors.response.use(undefined, error => {
  if(error.message === 'Network Error' && !error.response){
    toastr.error('Niste povezani na internet!', "Provjerite vašu internet konekciju.", {timeOut: 10000});
  }
});
*/
function App() {
  return (
    <BrowserRouter>
      <Sidebar>
        <AppRouter />
      </Sidebar>
    </BrowserRouter>
  );
}

export default App;
