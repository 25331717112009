import React, {useState} from "react"
import { Link, useHistory } from "react-router-dom"
import { Col, Card, CardBody, CardTitle, InputGroup, Container, Form, FormGroup, Label, Row, Input, FormFeedback } from "reactstrap"
import { AvForm, AvField, AvGroup, AvFeedback } from "availity-reactstrap-validation"
//import AvDate from '@availity/reactstrap-validation-date';
import moment from 'moment';
//import '@availity/reactstrap-validation-date/styles.scss';
import { useDispatch, useSelector } from "react-redux";
import { createPatient, resellerSelector, clearState } from "../../slices/ResellerSlice";
import { userSelector } from "../../slices/UserSlice";

import InputMask from "react-input-mask"
// import images
import ReactInputDateMask from "../../components/ReactInputDateMask";
import * as c from "../../app/config";

const CreatePatient = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { loading, error, errors } = useSelector(resellerSelector);
  //const { roles } = useSelector(userSelector);
  const roles = localStorage.getItem("roles") || "";
  const [ birthDate, setBirthDate ] = useState(c.getCurrentDate());
  const [ checkoutDate, setCheckoutDate ] = useState(c.getCurrentDate());
  const [ samplingType, setSamplingType ] = useState(false);

  const types = [
    {
      id: "6ac5d4a4-5030-4db2-aedd-c526739b5fb4",
      name: "PCR"
    },
    {
      id: "db94dfdd-4716-45db-9586-90e6b83d21b7",
      name: "Antigen"
    }
  ];

  const [checkedState, setCheckedState] = useState(
    new Array(types.length).fill(false)
  );

  const handleTypeOnChange = (position) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );
    setCheckedState(updatedCheckedState);
  }

  const handeSubmit = (event, errors, values) => {
    event.preventDefault();
    //poziv
    values['birthDate'] = birthDate;
    values['checkoutDate'] = checkoutDate;
    values['in_object'] = samplingType ? 1 : 0;
    var typeNames = [];
    checkedState.map((item, index) => item === true ? typeNames.push(types[index].id): null);
    values['types'] = typeNames;
    dispatch(createPatient(values, history));
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Prijem</CardTitle>
                  <AvForm className="form-horizontal" onSubmit={handeSubmit}>
                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <FormGroup>
                            <AvField
                              name="firstname"
                              label="Ime"
                              className="form-control"
                              placeholder="Unesite ime"
                              type="text"
                              errorMessage="Molimo unesite ime pacijenta"
                              invalid={errors?.firstname}
                              required
                            />
                            {errors?.firstname && <FormFeedback className={"d-block ".concat(errors?.firstname ? "visible" : "invisible")}>{errors?.firstname}</FormFeedback>}
                          </FormGroup>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <FormGroup>
                            <AvField
                              name="lastname"
                              label="Prezime"
                              className="form-control"
                              placeholder="Unesite prezime"
                              type="text"
                              errorMessage="Molimo unesite prezime pacijenta"
                              invalid={errors?.lastname}
                              required
                            />
                            {errors?.lastname && <FormFeedback className={"d-block ".concat(errors?.lastname ? "visible" : "invisible")}>{errors?.lastname}</FormFeedback>}
                          </FormGroup>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <FormGroup>
                            <AvField
                              name="email"
                              label="Email"
                              className="form-control"
                              placeholder="Unesite email"
                              type="email"
                              errorMessage="Molimo unesite email pacijenta"
                              invalid={errors?.email}
                              required
                            />
                            {errors?.email && <FormFeedback className={"d-block ".concat(errors?.email ? "visible" : "invisible")}>{errors?.email}</FormFeedback>}
                          </FormGroup>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <FormGroup>
                            <AvField
                              name="phone"
                              label="Broj telefona"
                              className="form-control"
                              placeholder="Unesite broj telefona"
                              className="form"
                              type="text"
                              errorMessage="Molimo unesite broj telefona pacijenta"
                              invalid={errors?.phone}
                              required
                            />
                            {errors?.phone && <FormFeedback className={"d-block ".concat(errors?.phone ? "visible" : "invisible")}>{errors?.phone}</FormFeedback>}
                          </FormGroup>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={4}>
                        <div className="mb-3">
                          <FormGroup>
                            <AvField type="select" name="sex" label="Spol">
                              <option>Izaberite spol</option>
                              <option value="MUŠKI">MUŠKI</option>
                              <option value="ŽENSKI">ŽENSKI</option>
                            </AvField>
                            {errors?.sex && <FormFeedback className={"d-block ".concat(errors?.sex ? "visible" : "invisible")}>{errors?.sex}</FormFeedback>}
                          </FormGroup>
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3">
                          <FormGroup>
                            <label>
                              Datum rođenja
                            </label>
                            <ReactInputDateMask
                              mask='dd.mm.yyyy'
                              showMaskOnFocus={false}
                              className="form-control"
                              value={"xx.xx.xxxx"}
                              onChange={setBirthDate}
                              showMaskOnHover={true}
                            />
                            {errors?.birthDate && <FormFeedback className={"d-block ".concat(errors?.birthDate ? "visible" : "invisible")}>{errors?.birthDate}</FormFeedback>}
                          </FormGroup>
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3">
                          <FormGroup>
                            <label>
                              Datum putovanja
                            </label>
                            <ReactInputDateMask
                              mask='dd.mm.yyyy'
                              showMaskOnFocus={false}
                              className="form-control"
                              value={"xx.xx.xxxx"}
                              onChange={setCheckoutDate}
                              showMaskOnHover={true}
                            />                            
                            {errors?.checkoutDate && <FormFeedback className={"d-block ".concat(errors?.checkoutDate ? "visible" : "invisible")}>{errors?.checkoutDate}</FormFeedback>}
                          </FormGroup>
                        </div>
                      </Col>
                    </Row>
                    {roles.includes("Hotel") && 
                      <div className="mb-3">
                        <div className="form-check">
                          <Input
                            type="checkbox"
                            className="form-check-Input"
                            name="samplingType"
                            id="samplingType"
                            checked={samplingType}
                            onChange={(e) => setSamplingType(e.target.checked)} 
                          />
                          <Label
                            className="form-check-Label"
                            htmlFor="samplingType"
                          >
                            Uzorkovanje u hotelu
                          </Label>
                        </div>
                      </div>
                    }
                    <div className="mb-3">
                      <label>
                        Tip testa
                      </label>
                      <ul>
                        {types.map(({ name }, index) => {
                          return (
                            <li key={index}>
                              <div className="block text-gray-700">
                                <Input
                                  type="checkbox"
                                  className="form-check-Input"
                                  id={`custom-checkbox-${index}`}
                                  name={name}
                                  value={name}
                                  checked={checkedState[index]}
                                  onChange={() => handleTypeOnChange(index)}
                                />
                                <Label 
                                  className="form-check-Label"
                                  htmlFor={`custom-checkbox-${index}`}>{"   \n"}{name}
                                </Label>
                              </div>
                            </li>
                          );
                        })}
                        <FormGroup>
                          {errors?.types && <FormFeedback className={"d-block ".concat(errors?.types ? "visible" : "invisible")}>{errors?.types}</FormFeedback>}
                        </FormGroup>
                      </ul>
                    </div>
                    <div>
                      <button type="submit" className="btn btn-primary w-md">
                        {!loading ? "Spremi": "Učitavanje..."}
                      </button>
                    </div>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>  
          </Row>        
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CreatePatient;