import { combineReducers } from "redux";
import { configureStore } from '@reduxjs/toolkit';
import { userReducer } from '../slices/UserSlice';
import {reducer as toastrReducer} from 'react-redux-toastr';
import { resellerReducer } from "../slices/ResellerSlice";
import { adminReducer } from "../slices/AdminSlice";
const rootReducer = combineReducers({
  user: userReducer,
  reseller: resellerReducer,
  admin: adminReducer,
  toastr: toastrReducer,
});
export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production',
});
/*
export const store = configureStore({
  reducer: {
    user: userReducer,
    toastr: toastrReducer,
      patiences: patiencesReducer
    
  },
  devTools: process.env.NODE_ENV !== 'production',
});

*/
