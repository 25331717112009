import React from "react"
import { Link, useLocation } from "react-router-dom"
import { Col, Container, Form, FormGroup, Label, Row, Input, FormFeedback } from "reactstrap"

import { AvForm, AvField, AvGroup, AvFeedback } from "availity-reactstrap-validation"
import { useDispatch, useSelector } from "react-redux";
import { resetPassword, userSelector, clearState } from "../../slices/UserSlice";
// import images
import CarouselPage from "./CarouselPage"
import queryString from 'query-string';

const ForgotPassword = () => {
  const { search } = useLocation();
  const dispatch = useDispatch();
  const { loading, error, errors } = useSelector(userSelector);

  const values = queryString.parse(search);
  let token = values.token;
  let username = values.username;

  const handleResetPassword = (event, errors, values) => {
    event.preventDefault();
    console.log("KLIK");
    //poziv
    dispatch(resetPassword(values));
  };

  //
  const form = React.createRef();
  return (
    <React.Fragment>
       <div>
          <Container fluid className="p-0">
            <Row className="g-0">
              <CarouselPage />
              <Col xl={3}>
                <div className="auth-full-page-content p-md-5 p-4">
                  <div className="w-100">
                    <div className="d-flex flex-column h-100">
                      <div className="my-auto">
                        <div>
                          <h5 className="text-primary">Zahtjev za resetovanje šifre</h5>
                        </div>

                        <div className="mt-4">
                          <AvForm className="form-horizontal" onSubmit={handleResetPassword} ref={form}>
                            <div className="mb-3">
                              <FormGroup>
                                <AvField
                                  name="token"
                                  label="Token"
                                  className="form-control"
                                  placeholder="Unesite token"
                                  type="text"
                                  value={token}
                                  errorMessage="Molimo unesite token"
                                  invalid={errors?.token}
                                />
                                {errors?.password && <FormFeedback className={"d-block ".concat(errors?.password ? "visible" : "invisible")}>{errors?.password}</FormFeedback>}
                              </FormGroup>
                            </div>
                            <div className="mb-3">
                              <FormGroup>
                                <AvField
                                  name="username"
                                  label="Korisničko ime"
                                  className="form-control"
                                  placeholder="Unesite korisničko ime"
                                  type="text"
                                  value={username}
                                  errorMessage="Molimo unesite korisničko ime"
                                  invalid={errors?.username}
                                />
                                {errors?.password && <FormFeedback className={"d-block ".concat(errors?.password ? "visible" : "invisible")}>{errors?.password}</FormFeedback>}
                              </FormGroup>
                              </div>
                            <div className="mb-3">
                              <FormGroup>
                                <AvField
                                  name="password"
                                  label="Šifra"
                                  className="form-control"
                                  placeholder="Unesite šifru"
                                  type="password"
                                  required
                                  errorMessage="Molimo unesite šifru"
                                  invalid={errors?.password}
                                />
                                {errors?.password && <FormFeedback className={"d-block ".concat(errors?.password ? "visible" : "invisible")}>{errors?.password}</FormFeedback>}
                              </FormGroup>
                            </div>
                            <div className="mb-3">
                              <AvField
                                name="password_confirmation"
                                label="Potvrda šifre"
                                className="form-control"
                                placeholder="Unesite potvrdu šifre"
                                type="password"
                                required
                                errorMessage="Molimo unesite potvrdu šifre"
                                invalid={errors?.password_confirmation}
                              />
                                {errors?.password && <FormFeedback className={"d-block ".concat(errors?.password ? "visible" : "invisible")}>{errors?.password}</FormFeedback>}
                            </div>
                            <div className="mt-3 d-grid">
                              <button
                                className="btn btn-primary btn-block "
                                type="submit"
                              >
                                {loading ? "Učitavanje...": "Resetuj"}
                              </button>
                            </div>
                            <div className="mt-3 d-grid">
                              <Link
                                to="/auth/login"
                                className="btn btn-primary btn-block"
                              >
                                Vrati se na prijavu
                              </Link>
                            </div>
                          </AvForm>
                        </div>
                      </div>

                      <div className="mt-4 mt-md-5 text-center">
                        <p className="mb-0">
                          © {new Date().getFullYear()} Labos.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
    </React.Fragment>
  )
}

export default ForgotPassword
