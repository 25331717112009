import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, CardTitle, Container, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, FormFeedback } from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import { resellerSelector, getPatients, clearErrors, updatePatient, deletePatient, getStatistics } from "../../slices/ResellerSlice";
import { AvForm, AvField } from "availity-reactstrap-validation"
import ReactInputDateMask from "../../components/ReactInputDateMask";
import DoubleScrollbar from 'react-double-scrollbar'
import * as c from "../../app/config";
import { me, userSelector, clearState } from "../../slices/UserSlice";

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import overlayFactory from 'react-bootstrap-table2-overlay';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import filterFactory, { multiSelectFilter } from 'react-bootstrap-table2-filter';

//Import Breadcrumb
//import Breadcrumbs from "../../components/Common/Breadcrumb"
import "../../assets/scss/datatables.scss"

const Patients = () => {
  const dispatch = useDispatch();
  const { data } = useSelector(userSelector);
  const { errors, loading, patients, statistic } = useSelector(resellerSelector);

  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [deleteModal, setDeleteModal] = useState(false);
  const [user, setUser] = useState([])
  const [ birthDate, setBirthDate ] = useState(c.getCurrentDate());
  const [ checkoutDate, setCheckoutDate ] = useState(c.getCurrentDate());
  const [ defaultValues, setDefaultValues ] = useState();

  const toggle = () => {
    setModal(!modal)
  }

  const toggleDeleteModal = () => {
    setDeleteModal(!deleteModal)
  }

  const handleEditClick = (editUser) => {
    dispatch(clearErrors());
    setUser(editUser);
    setBirthDate(editUser.birthdayDate);
    setDefaultValues({
      sex: editUser.sex
    });
    setIsEdit(true);
    toggle();
  }

  const handleValidSubmit = (event, errors, values) => {
    event.preventDefault();
    if(isEdit){
      // update
      values['birthDate'] = birthDate;
      values['checkoutDate'] = checkoutDate;
      values['id'] = user.id;
      dispatch(updatePatient(values, toggle));
    }
    //toggle()
  }
  
  // delete patient
  const handleDeleteClick = (deleteUser) => {
    setUser(deleteUser);
    toggleDeleteModal();
  }
  
  const handleDeleteSubmit = (event) => {
    event.preventDefault();
    dispatch(deletePatient(user.id, toggleDeleteModal))
  }

  useEffect(() => {
    dispatch(me());
    dispatch(getPatients());
  }, [dispatch]);

  const columns = [{
    dataField: 'name',
    text: 'Ime i prezime',
    sort: true,
  }, {
    dataField: 'email',
    text: 'Email',
    sort: true
  }, {
    dataField: 'phone',
    text: 'Broj telefona',
    sort: true,
  }, {
    dataField: 'sex',
    text: 'Spol',
    sort: true
  }, {
    dataField: 'created_at',
    text: 'Datum registracije',
    sort: true,
  }, {
    dataField: 'updated_at',
    text: 'Datum zadnje aktivnosti',
    sort: true,
  }, {
    dataField: "menu",
    isDummyField: true,
    editable: false,
    text: 'Akcije',
    sort: true,
    formatter: (cellContent, user) => (
      <div className="d-flex gap-3">
        <div 
          className="text-success"
          onClick={() => handleEditClick(user)}
        >
          <i className="mdi mdi-pencil font-size-18"></i>
        </div>
        <div 
          className="text-danger"
          onClick={() => handleDeleteClick(user)}
        >
          <i className="mdi mdi-delete font-size-18"></i>
        </div>
      </div>
    ),
  }];

  const defaultSorted = [{
    dataField: 'id',
    order: 'asc'
  }];

  const pageOptions = {
    sizePerPage: 10,
    totalSize: patients.length, // replace later with size(customers),
    custom: true,
  }

  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: '5', value: 5 },
    { text: '10', value: 10 },
    { text: '15', value: 15 },
    { text: '20', value: 20 },
    { text: '25', value: 25 },
    { text: 'All', value: (patients).length }
  ];

  const { SearchBar } = Search;

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/*<Breadcrumbs title="Tables" breadcrumbItem="Data Tables" />*/}
            <Row>
              <Col xl={12}>
                <Row>
                  <Col lg={3}>
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex flex-wrap">
                          <div className="me-3">
                            <p className="text-muted mb-2">Broj pacijenata</p>
                            <h5 className="mb-0"> {loading ? <div class="spinner-grow text-success" role="status"><span class="sr-only">Loading...</span></div>: statistic?.total}</h5>
                          </div>
                          <div className="avatar-sm ms-auto">
                            <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                              <i className="fas fa-users"></i>
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg={3}>
                    <Card className="blog-stats-wid">
                      <CardBody>
                        <div className="d-flex flex-wrap">
                          <div className="me-3">
                            <p className="text-muted mb-2">Danas</p>
                            <h5 className="mb-0"> {loading ? <div class="spinner-grow text-success" role="status"><span class="sr-only">Loading...</span></div>: statistic.patientsToday?.count}</h5>
                            {data.displayPrices && 
                              <p class="card-text float-right">
                                <small class="text-muted">{statistic.patientsToday?.money} KM</small>
                              </p>
                            }
                          </div>
                          <div className="avatar-sm ms-auto">
                            <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                              <i className="fas fa-users"></i>
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg={3}>
                    <Card className="blog-stats-wid">
                      <CardBody>
                        <div className="d-flex flex-wrap">
                          <div className="me-3">
                            <p className="text-muted mb-2">Ovaj mjesec</p>
                            <h5 className="mb-0"> {loading ? <div class="spinner-grow text-success" role="status"><span class="sr-only">Loading...</span></div>: statistic.patientsThisMonth?.count}</h5>
                            {data.displayPrices && 
                              <p class="card-text float-right">
                                <small class="text-muted">{statistic.patientsThisMonth?.money} KM</small>
                              </p>
                            }
                          </div>

                          <div className="avatar-sm ms-auto">
                            <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                              <i className="fas fa-users"></i>
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg={3}>
                    <Card className="blog-stats-wid">
                      <CardBody>
                        <div className="d-flex flex-wrap">
                          <div className="me-3">
                            <p className="text-muted mb-2">Ove godine</p>
                            <h5 className="mb-0"> {loading ? <div class="spinner-grow text-success" role="status"><span class="sr-only">Loading...</span></div>: statistic.patientsThisYear?.count}</h5>
                            {data.displayPrices &&
                              <p class="card-text float-right">
                                <small class="text-muted">{statistic.patientsThisYear?.money} KM</small>
                              </p>
                            }
                          </div>

                          <div className="avatar-sm ms-auto">
                            <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                              <i className="fas fa-users"></i>
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <CardTitle className="h4">Pregled pacijenata</CardTitle>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField='test'
                      columns={columns}
                      data={patients}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField='test'
                          columns={columns}
                          data={patients}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col md="4">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        placeholder="Pretraga"
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <DoubleScrollbar>
                                      <BootstrapTable
                                        keyField={"test"}
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        defaultSorted={defaultSorted}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        headerWrapperClasses={"thead-light"}
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                        /*overlay={ 
                                          overlayFactory({
                                            spinner: true,
                                            styles: { 
                                              spinner: (base) => ({
                                                ...base, 
                                              '& svg circle': {
                                                stroke: '#80c44a'
                                              }
                                            })
                                          } 
                                        }) 
                                      }
                                      */
                                      loading={loading}
                                      noDataIndication={ () => (<div>{loading ? "Ucitavanje...": "Nema rezultata."}</div>) }
                                      />
                                    </DoubleScrollbar>
                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )
                          }
                        </ToolkitProvider>
                      )
                      }</PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Modal
              isOpen={deleteModal}
              toggle={toggleDeleteModal}
            >
              <ModalHeader toggle={toggleDeleteModal} tag="h3">
              </ModalHeader>
              <ModalBody>
                <p>Da li ste sigurni da želite obrisati {user.name}?</p>
              </ModalBody>
              <ModalFooter>
                <button type="button" className="btn btn-light" onClick={() => {
                  toggleDeleteModal()
                }}>Odustanite</button>
                <button type="button" className="btn btn-danger" onClick={handleDeleteSubmit}>Potvrdi</button>
              </ModalFooter>
            </Modal>

            <Modal
              isOpen={modal}
              toggle={toggle}
            >
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Uređivanje pacijenta" : "Kreiranje resellera"}
            </ModalHeader>
            <ModalBody>
              <AvForm
                onSubmit={
                  handleValidSubmit
                }
                model={defaultValues}
              >
                <Row form>
                  <Col xs={12}>
                    <div className="mb-3">
                      <FormGroup>
                        <AvField
                          name="firstname"
                          label="Ime"
                          className="form-control"
                          placeholder="Unesite ime"
                          type="text"
                          value={user?.firstname || ""}
                          invalid={errors?.firstname}
                          required
                        />
                        {errors?.firstname && <FormFeedback className={"d-block ".concat(errors?.firstname ? "visible" : "invisible")}>{errors?.firstname}</FormFeedback>}
                      </FormGroup>
                    </div>
                    <div className="mb-3">
                      <FormGroup>
                        <AvField
                          name="lastname"
                          label="Prezime"
                          className="form-control"
                          placeholder="Unesite prezime"
                          type="text"
                          value={user?.lastname || ""}
                          invalid={errors?.lastname}
                          required
                        />
                        {errors?.lastname && <FormFeedback className={"d-block ".concat(errors?.lastname ? "visible" : "invisible")}>{errors?.lastname}</FormFeedback>}
                      </FormGroup>
                    </div>
                    <div className="mb-3">
                      <FormGroup>
                        <AvField
                          name="email"
                          label="Email"
                          className="form-control"
                          placeholder="Unesite email"
                          type="email"
                          value={user?.email || ""}
                          invalid={errors?.email}
                          required
                        />
                        {errors?.email && <FormFeedback className={"d-block ".concat(errors?.email ? "visible" : "invisible")}>{errors?.email}</FormFeedback>}
                      </FormGroup>
                    </div>
                    <div className="mb-3">
                      <FormGroup>
                        <AvField
                          name="phone"
                          label="Broj telefona"
                          className="form-control"
                          placeholder="Unesite broj telefona"
                          type="text"
                          value={user?.phone|| ""}
                          invalid={errors?.phone}
                          required
                        />
                        {errors?.phone && <FormFeedback className={"d-block ".concat(errors?.phone ? "visible" : "invisible")}>{errors?.phone}</FormFeedback>}
                      </FormGroup>
                    </div>
                    <div className="mb-3">
                      <FormGroup>
                        <AvField type="select" name="sex" label="Spol">
                          <option>Izaberite spol</option>
                          <option value="MUŠKI">MUŠKI</option>
                          <option value="ŽENSKI">ŽENSKI</option>
                        </AvField>
                        {errors?.sex && <FormFeedback className={"d-block ".concat(errors?.sex ? "visible" : "invisible")}>{errors?.sex}</FormFeedback>}
                      </FormGroup>
                    </div>
                    <div className="mb-3">
                      <FormGroup>
                        <label>
                          Datum rođenja
                        </label>
                        <ReactInputDateMask
                          mask='dd.mm.yyyy'
                          showMaskOnFocus={false}
                          className="form-control"
                          value={birthDate}
                          onChange={setBirthDate}
                          showMaskOnHover={true}
                        />
                        {errors?.birthDate && <FormFeedback className={"d-block ".concat(errors?.birthDate ? "visible" : "invisible")}>{errors?.birthDate}</FormFeedback>}
                      </FormGroup>
                    </div>
                    <div className="mb-3">
                      <FormGroup>
                        <label>
                          Datum putovanja
                        </label>
                        <ReactInputDateMask
                          mask='dd.mm.yyyy'
                          showMaskOnFocus={false}
                          className="form-control"
                          value={checkoutDate}
                          onChange={setCheckoutDate}
                          showMaskOnHover={true}
                        />                            
                        {errors?.checkoutDate && <FormFeedback className={"d-block ".concat(errors?.checkoutDate ? "visible" : "invisible")}>{errors?.checkoutDate}</FormFeedback>}
                      </FormGroup>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                      >Spremi</button>
                    </div>
                  </Col>
                </Row>
              </AvForm>
            </ModalBody>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Patients
