import React, { useEffect } from "react";
import { Row, Col, Card, CardBody, CardTitle, Container } from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import { adminSelector, getPatients, getSamplings, editSamplingStatus } from "../../slices/AdminSlice";
// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import overlayFactory from 'react-bootstrap-table2-overlay';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import filterFactory, { multiSelectFilter } from 'react-bootstrap-table2-filter';
import DoubleScrollbar from 'react-double-scrollbar'

//Import Breadcrumb
//import Breadcrumbs from "../../components/Common/Breadcrumb"
import "../../assets/scss/datatables.scss"

const Samplings = () => {
  const dispatch = useDispatch();
  const { loading, samplings } = useSelector(adminSelector);

  const handleChangeStatus = (id) => {
    dispatch(editSamplingStatus(id));
  };

  useEffect(() => {
    dispatch(getSamplings());
  }, [dispatch]);
  
  const columns = [{
    dataField: 'id',
    text: 'ID',
    sort: true,
  }, {
    dataField: 'user.name',
    text: 'Ime i prezime',
    sort: true,
  }, {
    dataField: 'status',
    text: 'Objekt',
    sort: true,
    formatter: (cellContent, result) => (
      <div className="d-flex flex-column">
        <p>Naziv objekta: {result.object.name}</p>
        <p>Adresa: {result.object?.additional_info?.address ? result.object?.additional_info?.address: '/'}</p>
        <p>Email: {result.object.email}</p>
        <p>Telefon: {result.object.phone}</p>
      </div>
    )
  }, {
    dataField: 'admin.name',
    text: 'Odobrio',
    sort: true
  }, {
    dataField: '',
    text: 'Uzorkovanje u objektu',
    sort: true,
    formatter: (cellContent, result) => (
      <div className="d-flex flex-column">
        {result.in_object == 1 ? 
          <div
            className="badge badge-soft-primary font-size-11 m-1"
          >
            Da
          </div>
        :
          <div
            className="badge badge-soft-danger font-size-11 m-1"
          >
            Ne
          </div>
        }
      </div>
    )
  },{
    dataField: '',
    text: 'Testovi',
    sort: true,
    formatter: (cellContent, result) => (
      <div className="d-flex flex-column">
        {
          result.types.map((type, i) =>
            <div
              className="badge badge-soft-primary font-size-11 m-1"
              key={"_role_" + result.user.id + i}
            >
              {type.name} | Protokol: {type.key}
            </div>          
          )
        }
      </div>
    ),
  }, {
    dataField: 'checkout_date',
    text: 'Datum uzorkovanja/odjave iz hotela',
    sort: true,
  }, {
    dataField: 'created_at',
    text: 'Datum kreiranja',
    sort: true,
  },{
    dataField: "menu",
    isDummyField: true,
    editable: false,
    text: 'Akcije',
    sort: true,
    formatter: (cellContent, result) => (
      <div className="d-flex gap-3">
        {result.status == 0 ?
          <div
            className="text-success"
            onClick={() => { handleChangeStatus({
              id: result.id,
              data: {
                status: 1
              }
            })}}
          >
            <i className="mdi mdi-check font-size-18"></i>
          </div>
          :
          <div
            className="text-danger"
            onClick={() => { handleChangeStatus({
              id: result.id,
              data: {
                status: 0
              }
            })}}
          >
            <i className="mdi mdi-close-circle font-size-18"></i>
          </div>
        }
      </div>
    ),
  }];

  const defaultSorted = [{
    dataField: 'id',
    order: 'asc'
  }];

  const pageOptions = {
    sizePerPage: 10,
    totalSize: samplings.length, // replace later with size(customers),
    custom: true,
  }

  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: '5', value: 5 },
    { text: '10', value: 10 },
    { text: '15', value: 15 },
    { text: '20', value: 20 },
    { text: '25', value: 25 },
    { text: 'All', value: (samplings).length }
  ];

  const { SearchBar } = Search;

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/*<Breadcrumbs title="Tables" breadcrumbItem="Data Tables" />*/}
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <CardTitle className="h4">Pregled uzorkovanja</CardTitle>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField='name'
                      columns={columns}
                      data={samplings}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField='name'
                          columns={columns}
                          data={samplings}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>

                              <Row className="mb-2">
                                <Col md="4">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        placeholder="Pretraga"
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <DoubleScrollbar>
                                      <BootstrapTable
                                        keyField={"test"}
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        defaultSorted={defaultSorted}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        headerWrapperClasses={"thead-light"}
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                        overlay={ 
                                          overlayFactory({
                                            spinner: true,
                                            styles: { 
                                              spinner: (base) => ({
                                                ...base, 
                                                '& svg circle': {
                                                  stroke: '#80c44a'
                                                }
                                              })
                                            } 
                                          }) 
                                        }
                                        loading={loading}
                                        noDataIndication={ () => (<div>{loading ? "Ucitavanje...": "Nema rezultata."}</div>) }
                                      />
                                    </DoubleScrollbar>
                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )
                          }
                        </ToolkitProvider>
                      )
                      }</PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Samplings;
