import React, { useState, useEffect, useRef } from "react"
import { Link, useHistory, useRouteMatch, NavLink } from "react-router-dom"
import SimpleBar from "simplebar-react"
import { useSelector } from "react-redux";
import { userSelector } from "../slices/UserSlice";
import Topbar from "./Topbar";

const Sidebar = (props) => {
  const ref = useRef();
  const { data } = useSelector(userSelector);

  useEffect(() => {
    //ref.current.recalculate()
  })

  const showSidebar = useRouteMatch("/admin");
  const showResellerSidebar = useRouteMatch("/reseller");
  const showUserSidebar = useRouteMatch("/user");
  const showMeSidebar = useRouteMatch("/me");
  const roles = localStorage.getItem("roles") || "";
  if(showSidebar !== null || showUserSidebar !== null || showResellerSidebar !== null || showMeSidebar !== null){
    return (
      <React.Fragment>
        <Topbar name={data.name}/>
        <div id="layout-wrapper">
          <div className="vertical-menu">
            <div className="navbar-brand-box">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src="/logo-floating-01.png" logo alt="" height="20" />
                </span>
                <span className="logo-lg">
                  <img src="/ekartonv2-logo.png" alt="" height="40" />
                </span>
              </Link>
            </div>
            <div data-simplebar className="h-100">
              <SimpleBar style={{ maxHeight: "100%" }}>
                <div id="sidebar-menu">
                  <ul className="metismenu list-unstyled" id="side-menu">
                    <li className="menu-title">Menu</li>
                    <li>
                      <NavLink to="/user/home" exact={true} activeClassName="mm-active" className="">
                        <i className="mdi mdi-home-outline"></i>
                        <span>Dashboard</span>
                      </NavLink>
                    </li>
                    {!roles.includes("Reseller") && 
                      <li>
                        <NavLink to="/me/results" exact={true} activeClassName="mm-active" className="">
                          <i className="mdi mdi-file-document"></i>
                          <span>Rezultati</span>
                        </NavLink>
                      </li>
                    }
                  </ul>
                  {roles.includes("Reseller") && 
                    <ul className="metismenu list-unstyled" id="side-menu">
                      <li className="menu-title">Reseller</li>
                      <li>
                        <NavLink to="/reseller/patients/add" exact={true} activeClassName="mm-active" className="">
                          <i className="dripicons-document"></i>
                          <span>Prijem</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/reseller/results" exact={true} activeClassName="mm-active" className="">
                          <i className="mdi mdi-file-document"></i>
                          <span>Pregled rezultata</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/reseller/patients" exact={true} activeClassName="mm-active" className="">
                          <i className="fas fa-users"></i>
                          <span>Pregled pacijenata</span>
                        </NavLink>
                      </li>
                    </ul>
                  }
                  {roles.includes("Admin") && 
                    <ul className="metismenu list-unstyled" id="side-menu">
                      <li className="menu-title">Admin</li>
                      <li>
                        <NavLink to="/admin/patients" exact={true} activeClassName="mm-active" className="">
                          <i className="fas fa-users"></i>
                          <span>Pregled pacijenata</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/admin/resellers" exact={true} activeClassName="mm-active" className="">
                          <i className="fas fa-users-cog"></i>
                          <span>Pregled resellera</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/admin/samplings" exact={true} activeClassName="mm-active" className="">
                          <i className="fas fa-vial"></i>
                          <span>Pregled uzorkovanja</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/admin/activities" exact={true} activeClassName="mm-active" className="">
                          <i className="mdi mdi-chart-timeline"></i>
                          <span>Log aktivnosti</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/admin/upcoming-birthdays" exact={true} activeClassName="mm-active" className="">
                          <i className="fas fa-birthday-cake"></i>
                          <span>Nadolazeći rođendani</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/admin/appointments" exact={true} activeClassName="mm-active" className="">
                          <i className="fas fa-calendar-alt"></i>
                          <span>Pregled rezervacija</span>
                        </NavLink>
                      </li>
                    </ul>
                  }
                  <ul className="metismenu list-unstyled" id="side-menu">
                    <li className="menu-title">Postavke</li>
                    <li>
                      <NavLink to="/me/profile" exact={true} activeClassName="mm-active" className="">
                        <i className="bx bx-cog"></i>
                        <span>Profil</span>
                      </NavLink>
                    </li>
                    <li>
                      <a onClick={() => {localStorage.clear(); setTimeout(function(){ window.location.href = "/auth/login"; window.location.reload(); }, 1000);}} exact={true} activeClassName="mm-active" className="">
                        <i className="bx bx-log-out"></i>
                        <span>Odjavi se</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </SimpleBar>
            </div>
            <div className="sidebar-background"></div>
          </div>
          <div className="main-content">{props.children}</div>
        </div>
      </React.Fragment>
    )
  }else{
    return (
      <React.Fragment>
            <div id="layout-wrapper">{props.children}</div>
      </React.Fragment>
    );
  }
}

export default Sidebar;