import React, {useState,useEffect} from "react"
import { Link, useHistory } from "react-router-dom"
import { Col, Row, Container, Card, CardBody, CardTitle, Media } from "reactstrap"
// import images
import { useDispatch, useSelector } from "react-redux";
import { me, userSelector, clearState } from "../../slices/UserSlice";
import { resellerSelector, getStatistics } from "../../slices/ResellerSlice";
import ReactApexChart from "react-apexcharts"
import StackedChart from "../../components/Charts/StackedChart"


import * as c from "../../app/config";
const Home = () => {
  const dispatch = useDispatch();
  const roles = localStorage.getItem("roles") || "";
  const { data } = useSelector(userSelector);
  const { statistic, loading } = useSelector(resellerSelector);

  useEffect(() => {
    dispatch(me());
    dispatch(getStatistics());
  }, []);


  /* DONUT CHART */
  //let pcr = !loading ? parseFloat(statistic.patientsThisMonth?.pcr/(statistic.patientsThisMonth?.pcr + statistic.patientsThisMonth?.pcr)*100).toFixed(2) : 0;
  //let antigen = !loading ? parseFloat(statistic.patientsThisMonth?.antigen/(statistic.patientsThisMonth?.antigen + statistic.patientsThisMonth?.antigen)*100).toFixed(2) : 0;
  let pcr = !loading ? statistic.patientsThisMonth?.pcr : 0;
  let antigen = !loading ? statistic.patientsThisMonth?.antigen : 0;

  const series = [pcr, antigen]
  const options = {
    labels: ["PCR", "Antigen"],
    colors: ["#556ee6", "#34c38f"],
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
      verticalAlign: "middle",
      floating: false,
      fontSize: "14px",
      offsetX: 0,
      offsetY: -10,
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            height: 240,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
  }
  /* KRAJ DONUT CHART */


  /* MONTLY CHART */
  let pcr2 = !loading ? statistic?.monthly?.pcr : 0
  let antigen2 = !loading ? statistic?.monthly?.antigen : 0
  const stackedChartSeries = [
    {
      name: "PCR",
      data: pcr2,
    },
    {
      name: "Antigen",
      data: antigen2
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Row>
          <Col xl="4">
            <Card className="overflow-hidden">
              <div>
                {loading &&
                <div className="position-absolute overlay bg-light w-100 h-100 d-flex justify-content-center align-items-center" style={{width: 300, height: 200, zIndex: 3}}>
                  <div className="">  
                    <div className="spinner-grow text-primary" role="status" style={{width: 30, height: 30, zIndex: 20}}>
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                </div>
                }
                  <div className="bg-primary bg-soft z-index-5">
                    <Row>
                      <Col xs="7">
                        <div className="text-primary p-3">
                          <h5 className="text-primary">Dobrodošli nazad!</h5>
                          <p>{data.name}</p>
                        </div>
                      </Col>
                      <Col xs="5" className="align-self-end">
                        {/*<img src={`${c.API_URL}/${data.avatar}`} alt="" className="img-fluid" />*/}
                      </Col>
                    </Row>
                  </div>
                <CardBody className="pt-0">
                  <Row>
                    <Col sm="4">
                      <div className="avatar-md profile-user-wid mb-4">
                        {data?.avatar ?
                          <img
                            src={`${c.API_URL}/${data.avatar}`}
                            className="img-thumbnail rounded-circle"
                          />
                        :
                          <div className="avatar-md">
                            <span className="avatar-title rounded-circle">
                            {data?.name && data.name.charAt(0)}
                            </span>
                          </div>
                        }
                      </div>
                      <h5 className="font-size-15 text-truncate">{data.name}</h5>
                    </Col>

                    <Col sm="8">
                      <div className="pt-4">
                        <Row>
                          <Col xs="12">
                          {roles.includes("Reseller") &&
                            <div>
                              <p className="text-muted mb-0">Naziv: <b>{data.name}</b></p>
                              <p className="text-muted mb-0">Adresa: <b>{data.additional_info?.address}</b></p>
                            </div>
                          }
                          {!roles.includes("Reseller") &&
                            <div>
                              <p className="text-muted mb-0">Ime i prezime: <b>{data.name}</b></p>
                              <p className="text-muted mb-0">JMBG: <b>{data.jmbg}</b></p>
                              <p className="text-muted mb-0">Spol: <b>{data.sex}</b></p>
                            </div>
                          }
                            <p className="text-muted mb-0">Email: <b>{data.email}</b></p>
                            <p className="text-muted mb-0">Telefon: <b>{data.phone}</b></p>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </div>
            </Card>
            {roles.includes("Reseller") &&
              <Col xl="12">
                <Card className="mini-stats-wid">
                  {loading &&
                    <div className="position-absolute overlay bg-light w-100 h-100 d-flex justify-content-center align-items-center" style={{width: 300, height: 200, zIndex: 3}}>
                      <div className="">  
                        <div className="spinner-grow text-primary" role="status" style={{width: 30, height: 30, zIndex: 20}}>
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    </div>
                  }
                  <CardBody>
                    <CardTitle>Statistika: PCR / Antigen</CardTitle>
                    {!loading && 
                      <ReactApexChart
                        options={options}
                        series={series}
                        type={"donut"}
                        height="380"
                      />
                    }
                  </CardBody>
                </Card>
              </Col>
            }
          </Col>
          <Col xl="8">
            {roles.includes("Reseller") &&
              <Row>
                <Col md="4">
                  <Card className="mini-stats-wid">
                  {loading &&
                    <div className="position-absolute overlay bg-light w-100 h-100 d-flex justify-content-center align-items-center" style={{width: 300, height: 200, zIndex: 3}}>
                      <div className="">  
                        <div className="spinner-grow text-primary" role="status" style={{width: 30, height: 30, zIndex: 20}}>
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    </div>
                    }
                    <CardBody>
                      <Media>
                        <Media body>
                          <p className="text-muted fw-medium">
                            Ukupno danas
                          </p>
                          <h4 className="mb-0">{statistic.patientsToday?.count}</h4>
                          {data.displayPrices &&
                            <p class="card-text float-right">
                              <small class="text-muted">{statistic.patientsToday?.money} KM</small>
                            </p>
                          }
                        </Media>
                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                          <span className="avatar-title rounded-circle bg-primary">
                            <i className="fas fa-users"></i>
                          </span>
                        </div>
                      </Media>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="mini-stats-wid">
                  {loading &&
                    <div className="position-absolute overlay bg-light w-100 h-100 d-flex justify-content-center align-items-center" style={{width: 300, height: 200, zIndex: 3}}>
                      <div className="">  
                        <div className="spinner-grow text-primary" role="status" style={{width: 30, height: 30, zIndex: 20}}>
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    </div>
                    }
                    <CardBody>
                      <Media>
                        <Media body>
                          <p className="text-muted fw-medium">
                            Ukupno PCR
                          </p>
                          <h4 className="mb-0">{statistic.patientsPcr?.count}</h4>
                          {data.displayPrices &&
                            <p class="card-text float-right">
                              <small class="text-muted">{statistic.patientsPcr?.money} KM</small>
                            </p>
                          }
                        </Media>
                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                          <span className="avatar-title rounded-circle bg-primary">
                            <i className="fas fa-users"></i>
                          </span>
                        </div>
                      </Media>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="mini-stats-wid">
                  {loading &&
                    <div className="position-absolute overlay bg-light w-100 h-100 d-flex justify-content-center align-items-center" style={{width: 300, height: 200, zIndex: 3}}>
                      <div className="">  
                        <div className="spinner-grow text-primary" role="status" style={{width: 30, height: 30, zIndex: 20}}>
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    </div>
                    }
                    <CardBody>
                      <Media>
                        <Media body>
                          <p className="text-muted fw-medium">
                            Ukupno Antigen
                          </p>
                          <h4 className="mb-0">{statistic.patientsAntigen?.count}</h4>
                          {data.displayPrices &&
                            <p class="card-text float-right">
                              <small class="text-muted">{statistic.patientsAntigen?.money} KM</small>
                            </p>
                          }
                        </Media>
                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                          <span className="avatar-title rounded-circle bg-primary">
                            <i className="fas fa-users"></i>
                          </span>
                        </div>
                      </Media>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            }
            <Row>
              <Col xl="12">
                <Card className="mini-stats-wid">
                  {loading &&
                    <div className="position-absolute overlay bg-light w-100 h-100 d-flex justify-content-center align-items-center" style={{width: 300, height: 200, zIndex: 3}}>
                      <div className="">  
                        <div className="spinner-grow text-primary" role="status" style={{width: 30, height: 30, zIndex: 20}}>
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    </div>
                  }
                  <CardBody>
                    <Media>
                      <Media body>
                        <h4 className="mb-0">Obavijesti</h4>
                        <p>
                          <p className="text-muted">
                            Beta verzija
                          </p>
                          Puštena u rad nova verzija eKartona. Ukoliko primjetite neku grešku, molimo da nas kontaktirate preko email: info@labos.ba ili kontakt broja: +387 61 031 990.
                          <br />Vaš MBL Labos
                          <p className="text-muted">
                            18. juni 2021.
                          </p>
                        </p>
                      </Media>
                      <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                        <span className="avatar-title rounded-circle bg-primary">
                          <i className="fas fa-bullhorn"></i>
                        </span>
                      </div>
                    </Media>
                  </CardBody>
                </Card>
              </Col>
              {roles.includes("Reseller") &&
              <Col xl="12">
                <Card className="mini-stats-wid">
                  {loading &&
                    <div className="position-absolute overlay bg-light w-100 h-100 d-flex justify-content-center align-items-center" style={{width: 300, height: 200, zIndex: 3}}>
                      <div className="">  
                        <div className="spinner-grow text-primary" role="status" style={{width: 30, height: 30, zIndex: 20}}>
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    </div>
                  }
                  <CardBody>
                    {!loading && <StackedChart
                      series={stackedChartSeries}
                    />
                    }
                  </CardBody>
                </Card>
              </Col>
              }
            </Row>
        </Col>
      </Row>
    </div>
    </React.Fragment>
  )
}

export default Home;