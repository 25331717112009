import React, { useEffect } from "react";
import { Row, Col, Card, CardBody, CardTitle, Container } from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import { adminSelector, getUpcomingBirthdays } from "../../slices/AdminSlice";
// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import overlayFactory from 'react-bootstrap-table2-overlay';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import filterFactory, { multiSelectFilter } from 'react-bootstrap-table2-filter';
import DoubleScrollbar from 'react-double-scrollbar'

//Import Breadcrumb
//import Breadcrumbs from "../../components/Common/Breadcrumb"
import "../../assets/scss/datatables.scss"

const UpcomingBirthdays = () => {
  const dispatch = useDispatch();
  const { upcomingBirthdaysLoading, upcomingBirthdays } = useSelector(adminSelector);

  useEffect(() => {
    dispatch(getUpcomingBirthdays());
  }, [dispatch]);
  
  const columns = [{
    dataField: 'name',
    text: 'Ime i prezime',
    sort: true,
  }, {
    dataField: 'username',
    text: 'Username',
    sort: true,
  }, {
    dataField: 'phone',
    text: 'Broj telefona',
    sort: true
  }, {
    dataField: 'birthdayDate',
    text: 'Datum rođendana',
    sort: true,
  }];

  const defaultSorted = [{
    dataField: 'birthdayDate',
    order: 'desc'
  }];

  const pageOptions = {
    sizePerPage: 10,
    totalSize: upcomingBirthdays.length, // replace later with size(customers),
    custom: true,
  }

  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: '5', value: 5 },
    { text: '10', value: 10 },
    { text: '15', value: 15 },
    { text: '20', value: 20 },
    { text: '25', value: 25 },
    { text: 'All', value: (upcomingBirthdays).length }
  ];

  const { SearchBar } = Search;

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/*<Breadcrumbs title="Tables" breadcrumbItem="Data Tables" />*/}
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <CardTitle className="h4">Pregled nadolazećih rođendana</CardTitle>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField='name'
                      columns={columns}
                      data={upcomingBirthdays}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField='name'
                          columns={columns}
                          data={upcomingBirthdays}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>

                              <Row className="mb-2">
                                <Col md="4">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        placeholder="Pretraga"
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <DoubleScrollbar>
                                      <BootstrapTable
                                        keyField={"test"}
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        defaultSorted={defaultSorted}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        headerWrapperClasses={"thead-light"}
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                        overlay={ 
                                          overlayFactory({
                                            spinner: true,
                                            styles: { 
                                              spinner: (base) => ({
                                                ...base, 
                                                '& svg circle': {
                                                  stroke: '#80c44a'
                                                }
                                              })
                                            } 
                                          }) 
                                        }
                                        loading={upcomingBirthdaysLoading}
                                        noDataIndication={ () => (<div>{upcomingBirthdaysLoading ? "Ucitavanje...": "Nema rezultata."}</div>) }
                                      />
                                    </DoubleScrollbar>
                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )
                          }
                        </ToolkitProvider>
                      )
                      }</PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default UpcomingBirthdays;
