import { createSlice } from "@reduxjs/toolkit";
import {toastr} from 'react-redux-toastr';
import axios from "axios";
import * as c from "../app/config";

export function createPatient(payload, history) {
  return async (dispatch) => {
    dispatch(setLoading());
    axios({
      method: "post",
      url: `${c.API_URL}/reseller/patients`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`
      },
      data: payload
    })
    .then((response) => {
      toastr.success('Uspješno!', response.data.message);
      dispatch(clearState());
      dispatch(addPatient(response.data.data));
      dispatch(disableLoading());
    })
    .catch((error) => {
      if(error.response?.data?.message != "The given data was invalid."){
        toastr.error('Greška', error.response.data.message)
      }
      dispatch(setError(error.response.data.errors));
      dispatch(disableLoading());
    });
  };
}

export function updatePatient(payload, toggle) {
  return async (dispatch) => {
    dispatch(setLoading());
    axios({
      method: "put",
      url: `${c.API_URL}/reseller/patients/${payload.id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
      data: payload
    })
    .then((response) => {
      toastr.success('Uspješno!', response.data.message);
      dispatch(editPatient(response.data.data))
      dispatch(disableLoading());
      toggle(); // gasi modal
    })
    .catch((error) => {
      console.log(error);
      if(error.response?.data?.message != "The given data was invalid."){
        toastr.error('Greška', error.response.data.message)
      }
      dispatch(setError(error.response.data.errors));
      dispatch(disableLoading());
    });
  };
}

export function deletePatient(payload, toggleDeleteModal) {
  return async (dispatch) => {
    dispatch(setLoading());
    axios({
      method: "delete",
      url: `${c.API_URL}/reseller/patients/${payload}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        'Content-Type': 'application/json'
      },
    })
    .then((response) => {
      toastr.success('Uspješno!', response.data.message);
      dispatch(removePatient(payload))
      dispatch(disableLoading());
      toggleDeleteModal(); // gasi modal
    })
    .catch((error) => {
      console.log(error);
      if(error.response?.data?.message != "The given data was invalid."){
        toastr.error('Greška', error.response.data.message)
      }
      dispatch(setError(error.response.data.errors));
      dispatch(disableLoading());
    });
  };
}

/* RESULTS */
export function getPatients() {
  return async (dispatch) => {
    dispatch(setLoading());
    axios({
      method: "get",
      url: `${c.API_URL}/reseller/patients`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    })
    .then((response) => {
      dispatch(setPatients(response.data.data));
    })
    .catch((error) => {
      //dispatch(setError(error.response.data.errors));
      //toastr.error('Greška', error.response.data.message)
    });
  };
}

export function getPatientsResults() {
  return async (dispatch) => {
    dispatch(setLoading());
    axios({
      method: "get",
      url: `${c.API_URL}/reseller/results`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    })
    .then((response) => {
      dispatch(setPatientsResults(response.data.data));
    })
    .catch((error) => {
      dispatch(setError(error.response.data.errors));
      toastr.error('Greška', error.response.data.message)
    });
  };
}

export function getStatistics() {
  return async (dispatch) => {
    dispatch(setLoading());
    axios({
      method: "get",
      url: `${c.API_URL}/reseller/statistics`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    })
    .then((response) => {
      dispatch(setStatistic(response.data.data));
    })
    .catch((error) => {
     // dispatch(setError(error.response.data.errors));
      //toastr.error('Greška', error.response.data.message)
    });
  };
}

export const resellerSlice = createSlice({
  name: 'reseller',
  initialState: {
    loading: false,
    error: false,
    errors: '',
    patients: [],
    results: [],
    statistic: []
  },
  reducers: {
    clearState: (state) => {
      state.loading = false;
      state.errors = '';
      state.error = false;
      return state;
    },
    clearErrors: (state, {payload}) => {
      state.error = false;
      state.errors = '';
    },
    setLoading: (state) => {
      state.loading = true;
    },
    setError: (state, {payload}) =>{
      state.error = true;
      state.errors = payload;
      state.loading = false;
    },
    disableLoading: (state) => {
      state.loading = false;
    },
    setPatients: (state, {payload}) => {
      state.patients = payload.patients;
      state.statistic = payload.statistic;
      state.loading = false;
    },
    setStatistic: (state, {payload}) => {
      state.statistic = payload;
      state.loading = false;
    },
    setPatientsResults: (state, {payload}) => {
      state.results = payload;
      state.loading = false;
    },
    addPatient: (state, {payload}) => {
      state.patients.push(payload);
    },
    editPatient: (state, {payload}) => {
      let index = state.patients.findIndex(item => item.id == payload.id);
      state.patients[index] = payload;
    },
    removePatient: (state, {payload}) => {
      state.patients = state.patients.filter(item => item.id != payload)
    }
  },
});

export const { clearState, removePatient, setStatistic, setLoading, clearErrors, setError, disableLoading, setPatients, setPatientsResults, editPatient, addPatient } = resellerSlice.actions;
export const resellerSelector = (state) => state.reseller;
export const resellerReducer = resellerSlice.reducer;