import React, { useState } from "react"
import { Row, Col } from "reactstrap"
import ReactDrawer from 'react-drawer';
import { Link } from "react-router-dom"
import { Dropdown,DropdownToggle,DropdownMenu,DropdownItem } from "reactstrap"
import * as c from "../app/config";

const Topbar = props => {
  const [search, setsearch] = useState(false)
  const [megaMenu, setmegaMenu] = useState(false)
  const [socialDrp, setsocialDrp] = useState(false)
  const [menu, setMenu] = useState(false)

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

  const [position, setPosition] = useState();
  const [open, setOpen] = useState(false);

  const toggleTopDrawer = () => {
    setPosition('right');
    setOpen(!open)
  }

  const onDrawerClose = () => {
    setOpen(false);
  }

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        )
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }

  function tToggle() {
    var body = document.body;
    body.classList.toggle("vertical-collpsed");
    body.classList.toggle("sidebar-enable");
  }


  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <button
              type="button"
              onClick={() => {
                tToggle()
              }}
              className="btn btn-sm px-3 font-size-16 header-item "
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars" />
            </button>
          </div>
          <div className="d-flex">
            <Dropdown
              isOpen={menu}
              toggle={() => setMenu(!menu)}
              className="d-inline-block"
            >
              <DropdownToggle
                className="btn header-item "
                id="page-header-user-dropdown"
                tag="button"
              >
                {localStorage.getItem("avatarUrl") ?
                  <div>
                    <img
                      className="rounded-circle header-profile-user"
                      src={`${c.API_URL}/${localStorage.getItem("avatarUrl")}`}
                      alt="Header Avatar"
                    />
                    <span className="d-none d-xl-inline-block ms-2 me-1">{props.name}</span>
                    <i className="mdi mdi-chevron-down d-none d-xl-inline-block"/>
                  </div>
                  :
                  <div className="btn header-item d-flex justify-content-center align-items-center">
                    <div className="avatar-xs">
                      <span className="avatar-title rounded-circle">
                      {props?.name && props.name.charAt(0)}
                      </span>
                    </div>
                    <span className="d-none d-xl-inline-block ms-2 me-1">{props.name}</span>
                    <i className="mdi mdi-chevron-down d-none d-xl-inline-block"/>
                  </div>
                }
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <Link to="/me/profile" className="dropdown-item">
                  <i className="bx bx-user font-size-16 align-middle me-1"/>
                  Postavke
                </Link>
                <div className="dropdown-divider"/>
                <a onClick={() => {localStorage.clear(); setTimeout(function(){ window.location.href = "/auth/login"; window.location.reload(); }, 1000);}} className="dropdown-item">
                  <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger"/>
                  <span>Odjavi se</span>
                </a>
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
      </header>
    </React.Fragment>
  )
}
export default Topbar;